import React from 'react'
import classes from './ProductGroceryCard.module.css'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {database} from '../../../firebase'
import Modal from '../../Portal/Modal'
import HurrysIcon from '../../../assets/icon.png'

class ProductGroceryCard extends React.Component {
    constructor(props, context){
        super(props, context);
        this.state={
            current : this.props.productsList.Weights[Object.keys(this.props.productsList.Weights)[0]],
            showOption : false,
            showAdd : true,
            quantity : 1,
            showDropDown: false,
            foodType: 'NonVeg',
            isOpen: false,
        }
    }
    

    handleOptionSelect = (iterator) => {
        console.log('current : ', this.state.current)
        console.log('Show Options' , this.state.showOption)
 

        if(this.props.user.UserId !== null) {
            database.ref('/Users').child(this.props.user.UserId).child('Cart').child(this.props.productsList.Weights[iterator].PushId).on('value', snapshot => {
                if(snapshot.val()){
                    console.log('Product exists in the cart')
                    this.setState({
                        ...this.state,
                        showAdd : false,
                        quantity : snapshot.val().Qty,
                        showOption : false,
                        current : this.props.productsList.Weights[iterator]
                    })
                }
                else if(!snapshot.val()) {
                    console.log('Product does not exist')
                    this.setState({
                        ...this.state,
                        showAdd : true,
                        quantity : 0,
                        showOption : false,
                        current : this.props.productsList.Weights[iterator]
                    })
                }
            });
        }
    }

    handleAdd = (productList) => {
        console.log('UserId', this.props.user.UserId)
        console.log('PushId', this.state.current.PushId)
        console.log('Current', this.state.current)
        console.log('Quantity : ', this.state.quantity, ' Price : ', Number(this.state.current.Price))

        if(this.props.user.UserId === null) {
            window.alert("Alert! Please Login to add item")
            this.setState({ isOpen: true });
            return
        }

        var vendorId = localStorage.getItem("vendorId")
        console.log("Vendor Id", vendorId)
        if(vendorId !== null && vendorId !== this.props.shop.UserId ) {
            if(window.confirm("You can order only from one vendor. Are you want to remove the items from cart")) {
                database.ref().child("Users").child(this.props.user.UserId).child("Cart").remove()
                localStorage.removeItem("vendorId")

                // alert("Success!!. Cart Cleared. You can add the Item now")
                return
            } else {
                return
            }
        } else {
            localStorage.setItem("vendorId", this.props.shop.UserId)
        }



            var cartRef = database.ref().child("Users").child(this.props.user.UserId).child("Cart").child(this.state.current.PushId)
            cartRef.child("Image").set(productList.ItemImage1)
            cartRef.child("Name").set(productList.ItemName + " " +this.state.current.Name)
            cartRef.child("Price").set(this.state.current.Price)
            cartRef.child("PushId").set(this.state.current.PushId)
            cartRef.child("Qty").set("1")
            cartRef.child("Total").set(this.state.current.Price)
            cartRef.child("Units").set(productList.Unit)

        this.setState({
            ...this.state,
            quantity : 1,
            showAdd : false
        })
        // database.ref('/Users').child(this.props.user.UserId).child('Cart').child(this.props.productId).child(this.state.current.PushId).set(this.state.current)
        // .then((result) => {
        //     console.log('Result : ', result)
        //     this.props.onChangeTotal(this.state.current.Price)
        // })
        // .catch(err => console.log(err))
    }

    handleIncrease = (productId, pushId) => {

        let comp = this

        database.ref().child("Users").child(comp.props.user.UserId).child("Cart").child(pushId)
        .once("value", function(snapshot) {
            if(snapshot.exists()) {
                    console.log('Snapshot', snapshot.val())
                    let quantity = parseInt(snapshot.val().Qty) + 1 
                    let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)
                    let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(pushId)
                    updateCartRef.child('Qty').set(String(quantity))
                    updateCartRef.child('Total').set(String(total))
                    .then(() => {
                        comp.setState({
                            quantity : quantity,
                            showAdd: false,
                        })
                    })
                }
        })

    }

    handleDecrease = (productId, pushId) => {
        // alert("Go to Cart to clear Items")
        // return
        let comp = this
        // console.log('ShopID : ', shopId, ' ProductId  ', productId)
        database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(pushId).once('value', snapshot => {
            console.log('Snapshot', snapshot.val())
            let quantity = parseInt(snapshot.val().Qty) - 1 
            let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)
            
                if(quantity !== 0) { 
                            let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(pushId)
                            updateCartRef.child('Qty').set(quantity)
                            updateCartRef.child('RTotal').set(total)
                            updateCartRef.child('Total').set(total)
                    } else {
                        database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(pushId).remove()
                    }

                            // let st = comp.state.structure
                            // st[index].items[i].quantity = quantity
                            // comp.setState({
                            //     structure : st,
                            //     cost : comp.state.cost + Number(st[index].items[i].Price)
                            // }, () => {
                            //     comp.props.changeTotal(comp.state.cost)
                            // } )
                })
            // })
        // })
    }

    handleIconClick = () => {
        window.scroll(0,0)
        if(this.state.current){
            this.props.changeProduct(this.props.productId, this.state.current)
        }
    }

    componentDidMount() {
        // console.log('Card props : ', this.props);
        console.log(this.state.current)

        if(this.props.user.UserId !== null) {
            database.ref('/Users').child(this.props.user.UserId).child('Cart').child(this.state.current.PushId).on('value', snapshot => {
                if(snapshot.val()){
                    console.log('Product exists in the cart')
                    this.setState({
                        ...this.state,
                        showAdd : false,
                        quantity : snapshot.val().Qty
                    })
                }
                else if(!snapshot.val()) {
                    console.log('Product does not exist')
                    this.setState({
                        ...this.state,
                        showAdd : true,
                        quantity : 0
                    })
                }
            });
        }
        

        if(this.props.productsList.Weights){
            this.setState({
                ...this.state,
                showDropDown: false
            })
        }
    }

    render(){
        // console.log('this product list', this.props.red);
        return(
            <div  className={classes.Container}>
            {
                this.props.few ?
                    <span className={classes.Few}>ONLY FEW LEFT</span> : 
                    null
            }
                <Modal open = {this.state.isOpen} onClose = {()=> this.setState({ isOpen: false})}/>
                
                {
                    <NavLink to='/product' className={classes.Icon} onClick={this.handleIconClick} >
                        {this.props.productsList.ItemImage1 == "No"|| this.props.productsList.ItemImage1 == "" ?
                            <img src = {HurrysIcon} height = "200" width = "200" alt = "" /> :
                            <img src={this.props.productsList.ItemImage1} height = "200" width = "200" alt="Product Icon" /> }
                         </NavLink>
                }
                <div className={classes.Details}>
                    <div className={classes.Brand}>
                        {/* <div className={classes.VegBorder}>
                            <div className={classes.VegDot}></div>
                            </div> */}
                        <span style = {{color: "black"}}>{this.props.productsList.ItemName}</span>
                    </div>
                    <span className={classes.Name}>{this.props.productsList.ItemDescription}</span> <br />
                    <div className={classes.OptionsSelector} onClick={() => { this.setState({...this.state, showOption : true, showAdd: true})}}>
                        <span>{this.state.current.Name}</span>
                        <span className={classes.Triangle}></span>


                    </div> <br />
                    <div className={classes.HRLine}></div>
                    {

                            this.state.showOption ? 
                                <div className={classes.Options}>
                                    {this.props.productsList.Weights && Object.keys(this.props.productsList.Weights).map((iterator, i) => {
                                    return (
                                        <div key={i} className={classes.Option} onClick={() => this.handleOptionSelect(iterator)}>
                                            <span>{this.props.productsList.Weights[iterator].Name}</span>
                                            <div className={classes.Prices}>
                                                <span>{this.props.productsList.Weights[iterator].Price}</span>
                                                
                                            </div>
                                        </div>
                                    )})}
                                </div>
                            : null
                        }
                    <div className={classes.Prices}>
                        <div className={classes.Left}>
                            <span className={classes.TruePrice}>£ {this.state.current.Price}</span>
                            {/* <span className={classes.StdPrice}>₹{this.state.current.stdPrice}</span> */}
                        </div>
                        {
                            this.state.showAdd ? 
                            <button className={classes.ButtonAdd} onClick={() => this.handleAdd(this.props.productsList)} >ADD</button>
                            : 
                            <div className={classes.Quantity}>
                                <span className={classes.Highlite} onClick={() => this.handleDecrease(this.props.productId, this.state.current.PushId)}>-</span>
                                <span className={classes.Quant}>{this.state.quantity ? this.state.quantity : 1}</span>
                                <span className={classes.Highlite} onClick={() => this.handleIncrease(this.props.productId, this.state.current.PushId)}>+</span>
                            </div>
                        }
                        
                    </div>
                </div>
    
            </div>
        )
    }
}

{/* <span>{iterator.stdprice}</span> */}

const mapStateToProps = state => {
    return{
        red : state.red,
        user : state.usr
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChangeTotal : (price) => dispatch({
            type : 'CHANGE_TOTAL',
            payload : price
        }),
        changeProduct : (id, current) => dispatch({
            type : 'CHANGE_PRODUCT_ID',
            payload : id,
            current : current
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroceryCard)