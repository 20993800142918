import React, { useState, useEffect} from 'react';
import styles from './CustomerSupport.module.css';
import * as FaIcons from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import CustomerHeader from './CustomerHeader';
import { database} from '../../firebase';
import Whatsapp from '../../assets/whatsapp.svg';
import Footer from '../Footer/Footer';

const  CustomerSupport = () => {
    const [email, setEmail]  = useState("");
    const [callNumber, setCallNumber] = useState("");
    const [whatsAppNo, setWhatsApp] = useState("");

    useEffect(() => {
        fetchContactUsData();
    }, []);

    const fetchContactUsData = () =>{
        database.ref("/Masters/").once('value').then(snapshot => {
            const masterData = snapshot.val();
            setEmail(masterData.Email);
            setCallNumber(masterData.CallNumber);
            setWhatsApp(masterData.WhatsappNumber);
        });
    }
    
    return (
        <div className = {styles.container}>
            <CustomerHeader/>
            <a href = {`https://api.whatsapp.com/send/?phone=${whatsAppNo}&text&app_absent=0`} target="_blank">
            <div className = {styles.category_box}>
                <div className = {styles.content}>
                    <p className = {styles.text}>Chat with us</p>
                    <FaIcons.FaWhatsapp style = {{color:'#00b246', fontWeight:700, fontSize:'30px'}} />
                </div>
            </div>
            </a>

            <div className = {styles.category_box}>
                <div className = {styles.content}>
                    <p className = {styles.text}>Call Us</p>
                    <p className = {styles.text_second}><span style = {{color: "#00b246"}}>{callNumber}</span></p>
                </div>
            </div>

            <div className = {styles.category_box}>
                <div className = {styles.content}>
                    <p className = {styles.text}>Emai Us</p>
                    <p className = {styles.text_second}><span style = {{color: "#00b246"}}>{email}</span></p>
                </div>
            </div>

            <NavLink to = "/customer-faq">
            <div className = {styles.category_box}>
                <div className = {styles.content}>
                    <p className = {styles.text}>Browse FAQs</p>
                    <FaIcons.FaGlobe style = {{color:'#00b246', fontWeight:700, fontSize:'30px'}} />
                </div>
            </div>
            </NavLink>

        </div>

        
    )
}

export default CustomerSupport
