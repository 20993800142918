import React from 'react';
import classes from './ActiveOrderCard.module.css';
import avatar from '../../assets/avatar2.jpg';
import phone from '../../assets/phone.svg';
import { NavLink } from 'react-router-dom';

const ActiveOrderCard = ({ activeOrder }) => {
  const {
    Total,
    DeliveryName,
    DeliveryImage,
    DeliveryNumber,
    SellerLoc,
    Qty,
    SellerName,
    OrderNo,
    LocationCoordinates,
  } = activeOrder;

  return (
    <div className={classes.Container}>
      <div className={classes.ActiveTop}>
        <span className={classes.OuterCircle}>
          <span className={classes.InnerCircle}></span>
        </span>
        <span className={classes.OrderStatus}>Order picked up</span>
        <button className={classes.ButtonTrack}><a href={'order-tracking/'+OrderNo}>TRACK ORDER</a></button>
      </div>
      <div className={classes.Details}>
        <span className={classes.Left}>
          {Qty} items{' '}
          <span className={classes.Location}>from {SellerName}</span>
        </span>
        <span className={classes.Right}>
          23 minutes <span className={classes.Remaining}>remaining</span>
        </span>
      </div>
      <div className={classes.HRLine}></div>
      <div className={classes.Payment}>
        <div className={classes.Left}>
          <span className={classes.Paid}>Amount Paid</span>
          <span className={classes.Amount}>￡{Total}</span>
        </div>
        <span className={classes.Right}>Order ID #{OrderNo}</span>
      </div>
        {DeliveryName && DeliveryNumber ? (
            <>
            <div className={classes.HRLine}></div>
            <div className={classes.Delivery}>
            <div className={classes.Left}>
              <div className={classes.Image}>
                <img src={DeliveryImage} alt="Avatar" />
              </div>
    
              <div className={classes.Profile}>
                <span className={classes.Name}>{DeliveryName}</span>
                <span className={classes.Role}>Delivery Executive</span>
              </div>
            </div>
            <img className={classes.PhoneIcon} src={DeliveryNumber} alt="Phone Icon" />
          </div>
            </>
        ) : null}
      
      <div className={classes.HRLine}></div>

      {/* <p className={clas    ses.SupportCustomer}>CONTACT SUPPORT</p> */}
      <NavLink className={classes.SupportCustomer} to = "/customer-support">CONTACT SUPPORT</NavLink>
    </div>
  );
};

export default ActiveOrderCard;
