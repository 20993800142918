import React from 'react'
import classes from './PastOrderCard.module.css'
import fruits from '../../assets/fruits-vegetables.svg'
import {NavLink} from 'react-router-dom';
import Rating from '../Rating/Rating';

const PastOrderCard = ({pastOrder}) => {
    const {DeliveryTime, SellerName, Total, OrderNo, DeliveryCustomer} = pastOrder; 
    return(
        <a href={'order-tracking/'+OrderNo}>
        <div className={classes.Container}>
            <div className={classes.PastTop}>
                <div className={classes.Icon}>
                    <img src={fruits} alt="Product" />
                </div>
                <div className={classes.Details}>
                    <p className={classes.Time}>{DeliveryTime}</p>
                    <p className={classes.Item}>{Total} items from {SellerName}</p>
                </div>
            </div>
            <div className={classes.HRLine}></div>
            <div className={classes.Payment}>
                <span className={classes.AmountPaid}>Amount Paid <span>￡{Total}</span></span>
                <span className={classes.OrderID}>Order ID #{OrderNo}</span>
            </div>
            <div className={classes.HRLine}></div>
            
            { DeliveryCustomer ? <Rating value={DeliveryCustomer} /> : <NavLink to="/rate" onClick={() => { window.scroll(0,0) }} className={classes.RateOrder}>RATE YOUR ORDER</NavLink>}
            
        </div> </a>
    )
}

export default PastOrderCard