import React from 'react'
import NavbarAlt from '../NavbarAlt/NavbarAlt'
import classes from './ContactUs.module.css'
import GreenBG from '../../assets/GreenBottom.svg'
import Footer from '../Footer/Footer'
import Logo from '../../assets/LogoBlack.png'


const HelpAndSupport = (props) => {
    console.log('About : ', props.about==='true')
    return(
        <div className={classes.Container}>
            <NavbarAlt />
            <img className={classes.Background} src={GreenBG} alt="Background" />
            {
                <div className={classes.Main}>
                <div className={classes.Contact}>
                    <span className={classes.HeadingContact}>Help and Support</span>
                    <div className={classes.InfoPanel} style={{'padding' : `${props.about ? '100px 50px' : 'default'}`}}>
                        <div className={classes.Left}>
                            <span className={classes.Heading}><b><a style = {{textDecoration: "underline"}} href = 'https://api.whatsapp.com/send/?phone=7306021374&text&app_absent=0' target = "_blank">Chat With Us </a></b></span>
                            
                            <span className={classes.Heading}>Call Us : <span className={classes.Info}>07306021374</span></span>

                            <span className={classes.Heading}>Email : <a style = {{textDecoration: "underline"}} href="mailto:support@hurrys.co.uk"><span className={classes.Info}>support@hurrys.co.uk</span></a></span>
                        </div>
                        <div className={classes.Right}>
                        {
                            <img className={classes.Logo} src={Logo} alt="Logo" />
                        }

                        </div>
                    </div>
                </div>
            </div> 
            }
            
            <Footer />
        </div>
    )
}

export default HelpAndSupport