import React from 'react'
import classes from './PaymentTimeSlot.module.css'
import payment from '../../assets/payment.jpeg'
import Select from 'react-select'

class PaymentTimeSlot extends React.Component{
    state = {
        timeSlotOpen : false
    }

    handlePaymentTimeSlot = () => {
        this.setState({
            ...this.state,
            timeSlotOpen : !this.state.timeSlotOpen
        })
    }

    render() {
        const groupedOptions = [
            { value: 'morning', label: 'Morning' },
            { value: 'afternoon', label: 'Afternoon' },
            { value: 'evening', label: 'Evening' }
        ]

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };
        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };
        
        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        return(
            <div className={classes.Accordion} >
                <div className={classes.AccordionTop} onClick={this.handlePaymentTimeSlot}>
                    <div className={classes.AccordionTopText}>
                        <img src={payment} alt="Payment Icon" />
                        <span>Time Slot
                            <p>Not Selected</p>
                        </span>
                    </div>
                    <ion-icon name={this.state.timeSlotOpen ? "chevron-up-sharp" : "chevron-down-sharp"}></ion-icon>
                </div>
                <div className={this.state.timeSlotOpen ? `${classes.AccordionDrop} ${classes.AccordionDropExpand}` : `${classes.AccordionDrop}`}>

                    <div className={classes.TimeSlotDropItem}>
                        <input type="checkbox" className={classes.mr3}/>
                        <span>Deliver Immediately</span>
                    </div>
                    <div className={classes.TimeSlotDropItem}>
                        <input type="checkbox" className={classes.mr3}/>
                        <span>Select Custom Delivery Slot</span>
                    </div>
                    <div className={classes.TimeSlotDropItem}>
                        <Select
                            className={classes.TimeSlotDropDown}
                            options={groupedOptions}
                            formatGroupLabel={formatGroupLabel}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentTimeSlot