import React from 'react'
import classes from './ModalMember.module.css'
import firebase from '../../firebase'


class ModalMemberVendor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            dob: '',
            emailId: '',
            brandName: '',
            businessCategory: 'Select',
            address: '',
            postalCode: '',
            alternateNumber: '',
        }
    }

    handleChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.value
        })
    }

    componentDidMount() {
        window.scrollBy(0, 100);
    }

    handleSubmit(e) {
        e.preventDefault()

        let data = this.state

        if(data.fullName === '') {
            alert("Enter Full Name")
            document.getElementById("fullName").focus()
            return
        }

        if(data.alternateNumber === '') {
            alert("Enter Mobile Number")
            document.getElementById("alternateNumber").focus()
            return 
        }

        
        if(data.alternateNumber.length !== 10) {
            alert("Enter Correct Mobile Number")
            document.getElementById("alternateNumber").focus()
            return 
        }

        if(data.dob === '') {
            alert("Select Date of Birth")
            document.getElementById("dob").focus()
            return
        }

        if(data.emailId === '') {
            alert("Enter Email Id")
            document.getElementById("emailId").focus()
            return
        }

        if(data.brandName === '') {
            alert("Enter Business / Brand Name")
            document.getElementById("brandName").focus()
            return
        }

        if(data.businessCategory === 'Select') {
            alert("Select Business Category")
            document.getElementById("businessCategory").focus()
            return
        }

        if(data.address === '') {
            alert("Enter Address")
            document.getElementById("address").focus()
            return
        }

        if(data.postalCode === '') {
            alert("Enter Postal Code")
            document.getElementById("postalCode").focus()
            return
        }

        var firebaseref = firebase.database().ref().child("EnquiryVendor").push()
        firebaseref.child("PushId").set(firebaseref.getKey())
        firebaseref.child("FullName").set(data.fullName)
        firebaseref.child("Number").set(data.alternateNumber)
        firebaseref.child("Dob").set(data.dob)
        firebaseref.child("EmailId").set(data.emailId)
        firebaseref.child("BrandName").set(data.brandName)
        firebaseref.child("BusinessCategory").set(data.businessCategory)
        firebaseref.child("Address").set(data.address)
        firebaseref.child("PostaCode").set(data.postalCode)
        firebaseref.child("Status").set("Active")

        alert("Vendor Enquiry Added Successfully!!")

        window.location.reload()
        
    }

    closeForm(e) {
        window.location.reload()
    }


    render() {
        return(
            <div className={classes.Container}>
                <div className={classes.Main} style={{height:'80%'}}>
                    <form>
                        <span style={{fontWeight:'bold',fontSize:'30px', float: "right", textAlign: "right", cursor: "pointer"}} onClick = {this.props.triggerModel}>&times;</span>

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Full Name</label>
                        <input type = "text" id = "fullName" name = "fullName" onChange = {this.handleChange.bind(this)} placeholder="Eg . John Steve" />

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Mobile Number</label>    
                        <input type = "number"
                            style={{width:'100%'}}
                            placeholder="Enter phone number"
                            value={this.state.alternateNumber}
                            name = "alternateNumber"
                            id = "alternateNumber"
                            onChange={this.handleChange.bind(this)} />

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Date of Birth</label>
                        <input type="date" id = "dob" name = "dob" onChange = {this.handleChange.bind(this)} />    

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Email ID</label>    
                        <input type="email" id = "emailId" name = "emailId" onChange = {this.handleChange.bind(this)} placeholder="Eg : abc@gmail.com" />

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Buisness Name/Brand Name</label>    
                        <input type = "text" id = "brandName" name = "brandName" onChange = {this.handleChange.bind(this)} placeholder="Eg : Mc Donald's" />
                        
                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Buisness Category</label>    
                        <select placeholder="Category" type = "select" id = "businessCategory" name = "businessCategory" onChange = {this.handleChange.bind(this)}>
                            <option value = "Select">Select</option>
                            <option value = "Food Delivery">Food Delivery</option>
                            <option value = "Groceries & Essentials">Groceries & Essentials</option>
                            <option value = "Home Food">Home Food</option>
                            <option value = "Meet & Fish">Meet & Fish</option>
                            <option value = "Fruits & Vegetables">Fruits & Vegetables</option>
                            <option value = "Pet Supplies">Pet Supplies</option>
                            <option value = "Medicine Store">Medicine Store</option>
                            <option value = "Fashion">Fashion</option>
                            <option value = "Health & Wellness">Health & Wellness</option>
                        </select>
                        
                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Address</label>    
                        <input type = "text" id = "address" name = "address" onChange = {this.handleChange.bind(this)} placeholder="Eg : 88 Station Road Landon E54" />

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>PostalCode</label>    
                        <input type = "text" id = "postalCode" name = "postalCode" onChange = {this.handleChange.bind(this)} placeholder="Lu1 11A" /> <br />
                        
                        <button onClick = {this.handleSubmit.bind(this)}>Submit</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default ModalMemberVendor