import React from 'react'
import classes from './CartCard.module.css'
import CardItems from '../CartItems/CartItems'
import {connect} from 'react-redux'
import {database} from '../../../firebase'

class CartCard extends React.Component {
    constructor(props, context){
        super(props, context);
        this.state={
            store : this.props.red.selectedShop ? this.props.red.selectedShop : 'Empty',
            cart : null,
            structure : [],
            cost : 0
        }
    }

    handleIncrease = (shopId, productId) => {
        let comp = this
        console.log('ShopID : ', shopId, ' ProductId  ', productId)
        database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(shopId).once('value', snapshot => {
            console.log('Snapshot', snapshot.val())
            let quantity = parseInt(snapshot.val().Qty) + 1 
            let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)

            comp.state.structure.map((iterator, index) => {
                console.log('iterator : ', iterator)
                if(iterator.id === shopId) {
                            // database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(shopId)
                            let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(shopId)
                            updateCartRef.child('Qty').set(quantity)
                            updateCartRef.child('RTotal').set(total)
                            updateCartRef.child('Total').set(total)

                            // let st = comp.state.structure
                            // st[index].items[i].quantity = quantity
                            // comp.setState({
                            //     structure : st,
                            //     cost : comp.state.cost + Number(st[index].items[i].Price)
                            // }, () => {
                            //     comp.props.changeTotal(comp.state.cost)
                            // } )
                }
            })
        })

    }

    handleDecrease = (shopId, productId) => {
        let comp = this
        console.log('ShopID : ', shopId, ' ProductId  ', productId)
        database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(shopId).once('value', snapshot => {
            console.log('Snapshot', snapshot.val())
            let quantity = parseInt(snapshot.val().Qty) - 1 
            let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)

            comp.state.structure.map((iterator, index) => {
                console.log('iterator : ', iterator)
                if(iterator.id === shopId) {
                    if(quantity !== 0) { 
                            let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(shopId)
                            updateCartRef.child('Qty').set(quantity)
                            updateCartRef.child('RTotal').set(total)
                            updateCartRef.child('Total').set(total)
                    } else {
                        console.log("Quantity", quantity)
                        database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(shopId).remove()
                    }

                            // let st = comp.state.structure
                            // st[index].items[i].quantity = quantity
                            // comp.setState({
                            //     structure : st,
                            //     cost : comp.state.cost + Number(st[index].items[i].Price)
                            // }, () => {
                            //     comp.props.changeTotal(comp.state.cost)
                            // } )
                }
            })
        })
        // console.log(shopId, productId)
        // database.ref('/Users').child(this.props.user.UserId).child('Cart').child(shopId).child(productId).child('quantity').once('value', snapshot => {
        //     console.log('Snapshot', snapshot.val())
        //     let quantity = snapshot.val() ? snapshot.val() - 1 : 0
        //     console.log('Quantity : ', quantity)
        //     if(quantity===0) {
        //         database.ref('/Users').child(this.props.user.UserId).child('Cart').child(shopId).child(productId).set(null)
        //         let newStr = this.state.structure.filter((iterator, index) => {
        //             console.log('iterator : ', iterator)
        //             let valid = true
        //             if(iterator.id === shopId) {
        //                 valid = false
        //             }
        //             console.log('valid : ', valid)
        //             return valid
        //         })
        //         this.setState({
        //             ...this.state,
        //             structure : newStr
        //         })
        //         // let x = this.state.structure.map((iterator, index) => {
        //         //     if(iterator.id===shopId) {
        //         //         return iterator.items.filter((iterator_i, i) => {
        //         //             if(iterator_i.PushId!==productId) {
        //         //                 return true
        //         //             }
        //         //         })
        //         //     }
        //         // })
        //         // console.log('value of x : ', x)
        //     }
        //     else{
        //         this.state.structure.map((iterator, index) => {
        //             console.log('iterator : ', iterator)
        //             if(iterator.id === shopId) {
        //                 iterator.items.map((iterator_i, i) => {
        //                     if(iterator_i.PushId===productId) {
        //                         database.ref('/Users').child(this.props.user.UserId).child('Cart').child(shopId).child(productId).child('quantity').set(quantity)
        //                         let st = this.state.structure
        //                         st[index].items[i].quantity = quantity
        //                         this.setState({
        //                             ...this.state,
        //                             structure : st,
        //                             cost : this.state.cost - Number(st[index].items[i].Price)
        //                         }, () => {
        //                             this.props.changeTotal(this.state.cost)
        //                         })
        //                     }
        //                 })
        //             }
        //         })
        //     }
            
        // })

    }

    clearCart = () => {
        if(window.confirm("Are you sure want to clear the cart")) {
            database.ref('/Users').child(this.props.user.UserId).child('Cart').set(null)
            this.setState({
                ...this.state,
                cost : 0,
                structure : []
            })
            localStorage.removeItem("vendorId")
        } else {}

    }

    componentDidMount(){
        let total = 0
        database.ref("Users").child(this.props.user.UserId).child('Cart').on('value', snapshot => {
            if(snapshot.val()){
                let x = []
                let total = 0
                snapshot.forEach(function(snapshot) {

                    let itemQuantity = snapshot.val().Quantity ?  snapshot.val().Quantity : 1
                    total = (parseFloat(total) + parseFloat(itemQuantity*Number( snapshot.val().Total))).toFixed(2)
                        const item = {
                            id : snapshot.val().PushId,
                            icon : snapshot.val().Image,
                            name : snapshot.val().Name,
                            quantity : snapshot.val().Qty,
                            price : snapshot.val().Price,
                            items : []
                        }
                        x.push(item)
                    })

                    console.log("Cart Items", x)



                        this.setState({
                            structure : x
                        })

                        this.setState({
                                cost : total
                            }, () => {
                                console.log('State cost : ', this.state.cost)
                                this.props.changeTotal(this.state.cost)
                            })
                    }

        })
    }

    render(){
        let totalCost = 0
        return(
            <div className={classes.Container}>
                <div className={classes.Top}>
                    <div className={classes.Shop}>
                        <span className={classes.Name}>{this.state.store ? this.state.store.BusinessName : null}</span>
                        <span className={classes.Details}>{this.state.store.DeliveryTime ? this.state.store.DeliveryTime : null} mins delivery | {this.state.store.d ? this.state.store.d.toFixed(1) : null} miles away</span>
                    </div>
                    {/* <button className={classes.ButtonAddMore}>+ ADD MORE</button> */}
                </div>

                <div className={classes.Mid}>
                    <div className={classes.MidTop}>
                        <span className={classes.ItemCount}>ITEMS</span>
                        <button type="button" onClick={this.clearCart} >CLEAR</button>
                    </div>
                    <div className={classes.MidMid}>
                        {/* {
                            this.props.items.map((iterator, index) => {

                                    return(
                                        <CardItems key={index} {...iterator}  />
                                    )
                                
                            })
                        } */}
                            {console.log("Cart Showing Items", this.state.structure)}

                        {
                            this.state.structure.length !== 0 ? 
                            this.state.structure.map((iterator, index) => {
                                {console.log(iterator)}
                                // return(
                                        // let quantity = i.quantity ? i.quantity : 1
                                        // {console.log(iterator)}
                                        // totalCost = quantity * i.Price                             
                                        // console.log('i : ', i)
                                        return (<CardItems key={index} icon={iterator.icon} name={iterator.name} price={iterator.price} quant={iterator.name} quantity={iterator.quantity} shopId={iterator.id} productId={iterator.PushId} handleIncrease={this.handleIncrease} handleDecrease={this.handleDecrease} />)})

                                // )
                            // })
                            : 
                            <span>Cart is empty</span>
                        }
                    </div>
                </div>
                {/* <div className={classes.HRLine}></div> */}
                {/* <div className={classes.Cutlery}>
                    <input type="checkbox" />
                    <div className={classes.CutleryText}>
                        <span className={classes.Span1}>Include Cutlery</span>
                        <span className={classes.Span2}>Refuse cutlery, go eco-friendly & save ₹3</span>
                    </div>
                    <span className={classes.CutleryPrice}>₹3</span>
                </div> */}
                <div className={classes.HRLine}></div>
                <div className={classes.Total}>
                    <span onClick={() => {console.log(this.state.structure)}}>Total</span>
                    <span>£ {this.state.cost}</span>
                </div>
                <div className={classes.HRLine}></div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        user : state.usr,
        red : state.red
    }
}

const mapDispatchToProps = dispatch => {
    return{
        changeTotal : (price) => dispatch({
            type : 'SET_ITEMS_COST',
            payload : price
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartCard)   