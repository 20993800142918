const config = {
  STRIPE_SECRET_KEY:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_STRIPE_SECRET_KEY
      : process.env.REACT_APP_PROD_STRIPE_SECRET_KEY,
  STRIPE_PUBLISHABLE_KEY:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_STRIPE_PUBLISHABLE_KEY
      : process.env.REACT_APP_PROD_STRIPE_PUBLISHABLE_KEY,
};

export { config as default };
