import React from 'react'
import classes from './PaymentAccordion.module.css'
import payment from '../../assets/payment.jpeg'
import debitCard from '../../assets/debitCard.svg'
class PaymentAccordion extends React.Component{
    state = {
        paymentOpen : true
    }
    handlePaymentAccordion = () => {
        this.setState({
            ...this.state,
            paymentOpen : true
        })
    }
    render() {
        return(
            
            <div className={classes.Accordion} >
                <div className={classes.AccordionTop} onClick={this.handlePaymentAccordion}>
                    <div className={classes.AccordionTopText}>
                        <img src={payment} alt="Payment Icon" />
                        <span>Payment method
                            <p>Choose your payment method</p>
                        </span>
                    </div>
                    {/* <ion-icon name={this.state.paymentOpen ? "chevron-down-sharp" : "chevron-up-sharp"}></ion-icon> */}
                </div>
                <div className={this.state.paymentOpen ? `${classes.AccordionDrop} ${classes.AccordionDropExpand}` : `${classes.AccordionDrop}`}>

                    {/* <div className={classes.PaymentDropItem}>
                        <input type="checkbox" />
                        <img src={debitCard} alt="Debit Card" />
                        <span>Credit / Debit Card</span>
                    </div> */}
                    <div className={classes.PaymentDropItem}>
                        <input type="checkbox" checked />
                        <img src={debitCard} alt="Debit Card" />
                        <span>Debit/Credit Card</span>
                    </div>

                </div>
            </div>


        )
    }
}
export default PaymentAccordion