import React , {useState, useEffect} from 'react';
import * as LoIcons from 'react-icons/io';
import styles from '../Signupcontent.module.css';
// import LoginModal from './LoginModal';
import {database} from '../../../firebase';



function SignupContent({onClose, userInfo}) {
    const [state, setState] = React.useState({
        fullName: '',
        mobileNumber: '',
        email: ''
    })

    var mobileNumber = localStorage.getItem("MobileNumber")
    var email = localStorage.getItem("Email")

const handleInputChange = (e) => {
    setState({
        ...state,
        fullName: e.target.value
      });
}

useEffect(() => {
    var name = localStorage.getItem("Name")


    setState({
        ...state,
        fullName: name,
      });
},[])

const updateUserName = (e) => {
    console.log(state.fullName)

    const userId = localStorage.getItem("UserId");
    database.ref('Users').child(userId).child("Name").set(state.fullName)
    localStorage.setItem("Name", state.fullName)

    onClose()

    // setState({
    //     ...state,
    //     fullName: fullName
    // })
}


    return (
        <>
        <div className = {styles.signup_wrapper}>
             <div className = {styles.signup_header}>
                <LoIcons.IoIosClose onClick = {onClose} style = {{fontWeight:900, marginLeft:'auto',fontSize:50, cursor: "pointer" }} />
            </div>
            <div style = {{borderBottom:'2px solid #eaeaea'}}>
                    <p style ={{fontWeight:600, fontSize:'20px', marginLeft:'9%',marginBottom:'10px'}} >Edit Profile</p>
                </div>
            <div className = {styles.signup_content}>
                
              
                <div className = {styles.signup_input}>
                    <label className = {styles.flabel}>
                        <p>Full Name</p>
                        <input type = "text"  value = {state.fullName} name = "fullName" onChange = {handleInputChange} placeholder = "Full Name"/>
                    </label>
                    <label>
                        <p>Emai</p>
                        <input type = "text" value = {email} disabled placeholder = "Email"/>
                    </label>
                    <label>
                        <p>Phone Number</p>
                        <input type = "text" value = {mobileNumber} disabled placeholder = "977889922"/>
                    </label>
                    <div className = {styles.btn_wrapper}>
                        <button className = {styles.btn} onClick = {updateUserName}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SignupContent;

