import React from 'react'
import classes from './ProductGrocerMain.module.css'
import ProductGroceryCard from './ProductGroceryCard/ProductGroceryCard';
import ProductFoodCard from './ProductGroceryCard/ProductFoodCard'
import {connect} from 'react-redux'

class ProductGroceryMain extends React.Component{

    
    state={
        active : 'snacks',
    }

    componentDidMount() {
        this.props.itemSubCategory && this.props.itemSubCategory.ItemCategory && this.props.changeActiveItem(this.props.itemSubCategory.ItemCategory.split(',')[0])
    }
    render(){
        {console.log("Inside Grocery", this.props)}
        return(
            <div className={classes.Container}>
                <div className={classes.Menu}>
                    {/* {this.props.selectedShop.Category !== "Groceries & Essentials" ?  */}
                {
                    this.props.itemSubCategory && this.props.itemSubCategory.ItemCategory ? 
                    this.props.itemSubCategory.ItemCategory.split(',').slice(0, this.props.itemSubCategory.ItemCategory.split(',').length -1).map((iterator, index) => {
                        return( 
                            <div key={index} className={ this.props.itemCategory===iterator ? `${classes.MenuItem} ${classes.active}` : classes.MenuItem}
                                onClick={() => {
                                    this.props.changeActiveItem(iterator)
                                    window.scrollTo(0, 0)
                                    }}>
                                <span>{iterator}</span>
                            </div>
                        )
                    }) : null
                }

                {/* {
                    this.props.itemSubCategory && this.props.itemSubCategory.ItemCategory ? 
                    this.props.itemSubCategory.ItemCategory.split(',').slice(0, this.props.itemSubCategory.ItemCategory.split(',').length -1).map((iterator, index) => {
                        return( 
                            <div key={index} className={ this.props.itemCategory===iterator ? `${classes.MenuItem} ${classes.active}` : classes.MenuItem}
                                onClick={() => {
                                    this.props.changeActiveItem(iterator)
                                    window.scrollTo(0, 0)
                                    }}>
                                <span>{iterator}</span>
                            </div>
                        )
                    }) : null
                } </> } */}
                    {/* <div className={ this.props.productCategoryDisplay==='snacks' ? `${classes.MenuItem} ${classes.active}` : classes.MenuItem}
                        onClick={() => {
                            this.props.changeCategory('snacks')
                            window.scrollTo(0, 0)
                            }}>
                        <span>Snacks</span>
                    </div>
                    <div className={ this.props.productCategoryDisplay==='dairy' ? `${classes.MenuItem} ${classes.active}` : classes.MenuItem} onClick={() => this.props.changeCategory('dairy')}>
                        <span>Dairy & Eggs</span>
                    </div>
                    <div className={ this.props.productCategoryDisplay==='breakfast' ? `${classes.MenuItem} ${classes.active}` : classes.MenuItem} onClick={() => this.props.changeCategory('breakfast')}>
                        <span>Breakfast</span>
                    </div>
                    <div className={ this.props.productCategoryDisplay==='health' ? `${classes.MenuItem} ${classes.active}` : classes.MenuItem} onClick={() => this.props.changeCategory('health')}>
                        <span>Health & Wellness</span>
                    </div>
                    <div className={ this.props.productCategoryDisplay==='bakery' ? `${classes.MenuItem} ${classes.active}` : classes.MenuItem} onClick={() => this.props.changeCategory('bakery')}>
                        <span>Bakery</span>
                    </div>
                    <div className={ this.props.productCategoryDisplay==='sauce_dips' ? `${classes.MenuItem} ${classes.active}` : classes.MenuItem} onClick={() => this.props.changeCategory('sauce_dips')}>
                        <span>Sauce & Dips</span>
                    </div> */}
                </div>
                <div className={classes.HRLine}></div>
                <div className={classes.Main}>
                {console.log(this.props.selectedShop)}

                    { 
                      this.props.selectedShop && this.props.selectedShop.Products &&  Object.keys(this.props.selectedShop.Products).map((iterator, index) => {
                           {console.log("Iterator", iterator)}

                           if(this.props.selectedShop.Category !== "Groceries & Essentials") {
                            if(this.props.selectedShop.Products[iterator].ItemCategory===this.props.itemCategory) {
                                const productsList = this.props.selectedShop.Products[iterator];
                                // if(this.props.selectedShop.Products.Category ==='Home Food' || this.props.selectedShop.Products.Category === 'Food Delivery' ){
                                   return( <ProductFoodCard key={index}  productId={iterator} productsList={productsList} shop = {this.props.selectedShop}  />);
                                // }
                            }
                            } else {
                                if(this.props.selectedShop.Products[iterator].ItemCategory===this.props.itemCategory) {
                                const productsList = this.props.selectedShop.Products[iterator];
                                // if(this.props.selectedShop.Products.Category ==='Home Food' || this.props.selectedShop.Products.Category === 'Food Delivery' ){
                                return( <ProductGroceryCard key={index} productId={iterator} productsList={productsList} shop = {this.props.selectedShop}  />);
                                }
                                    
                            }
                        })
                    }
                    {/* {
                        this.props.selectedShop.map((iterator, index) => {
                            if(iterator.category===this.props.productCategoryDisplay) {
                                return(
                                    <ProductGroceryCard key={index} {...iterator}  />
                                )
                            }
                        })
                    } */}
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        productCategoryDisplay : state.red.productCategoryDisplay,
        selectedShop : state.red.selectedShop,
        itemSubCategory : state.red.itemSubCategory,
        itemCategory : state.red.itemCategory
    }
}

const mapDispatchToProps = dispatch => {
    return{
        changeCategory : (category) => dispatch({
            type : 'CHANGE_PRODUCT_CATEGORY',
            category
        }),
        changeActiveItem : (item) => dispatch({
            type : 'CHANGE_ACTIVE_ITEM',
            payload : item
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroceryMain);