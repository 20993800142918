import React from 'react'
import classes from './ShopsAvailable.module.css'
import NavbarAlt from '../NavbarAlt/NavbarAlt'
import Footer from '../Footer/Footer'
import grocery from '../../assets/grocery-icon.svg'
import food from '../../assets/food-icon.svg'
import fish from '../../assets/fish-meat.svg'
import pet from '../../assets/pet-supplies.svg'
import gift from '../../assets/gifts.svg'
import meds from '../../assets/medicine-stores.svg'
import apparels from '../../assets/apparels-copy.svg'
import health from '../../assets/group.svg'
import pkg from '../../assets/delivery.svg'
import homeFood from '../../assets/home-food.png'
import fruits from '../../assets/fruits-vegetables.svg'
import taxi from '../../assets/taxi.svg'
import bazaar from '../../assets/bazaar.jpeg'
import CategoryCard from '../Categories/CategoryCard/CategoryCard'
import CategoryCardShops from '../CategoryCardShops/CategoryCardShops'
import AvailableShopsCard from '../AvailableShopsCard/AvailableShopsCard'
import { connect } from 'react-redux'
import firebase, {database} from '../../firebase'
import UnAvailableShopsCard from '../UnAvailableShopsCard/UnAvailableShopsCard'

function checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
}

// var searchVend = sessionStorage.getItem("searchVend")
// console.log("searchVend", searchVend)

class ShopsAvailable extends React.Component{

    state={
        active: "Food Delivery",
        availableShopsActive : [],
        unAvailableShopsActive: []
    }

    handleCurrent = (value) => {
        this.setState({
            ...this.state,
            active : value
        })
    }

    componentDidMount() {

//     var str1 = "10:20:45";
//     var str2 = "05:10:10";

// if (str1 > str2)
//     alert("Time 1 is later than time 2");
// else
//     alert("Time 2 is later than time 1");

        database.ref('/Vendor').once('value', snapshot => {
            const allShopsObject = snapshot.val()
            const allShopsKeys = Object.keys(snapshot.val())
            const allShopsArray = allShopsKeys.map((iterator, index) => {
                if(allShopsObject[iterator].Location){
                    const shopLocation = allShopsObject[iterator].Location.split(',')

                    const lat1 = shopLocation[0]
                    const lon1 = shopLocation[1]
                    
                    const lat2 = this.props.activeLocation.latitude
                    const lon2 = this.props.activeLocation.longitude
                    var R = 6371; // Radius of the earth in km
                    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
                    var dLon = this.deg2rad(lon2-lon1); 
                    var a = 
                    Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
                    Math.sin(dLon/2) * Math.sin(dLon/2)
                    ; 
                    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
                    var d = R * c; // Distance in km
                    // return d;

                    var today = new Date();
                    var h = today.getHours();
                    var m = today.getMinutes();
                    var s = today.getSeconds();
                    // add a zero in front of numbers<10
                    m = checkTime(m);
                    s = checkTime(s);
                    var currentTime = h + ":" + m;

                    if(d<=this.props.activeCity.Radius && allShopsObject[iterator].ApprovalStatus === "Approved" && allShopsObject[iterator].Status === "Active" /* && allShopsObject[iterator].StoreOpenTime < currentTime && allShopsObject[iterator].StoreCloseTime > currentTime */) {
                         const ava = this.state.availableShopsActive
                        ava.push({
                            ...allShopsObject[iterator],
                            shopId : iterator,
                            d
                        })
                        this.setState({
                            ...this.state,
                            availableShopsActive : ava
                        })
                    }
                else {
                    const unAvailable = this.state.unAvailableShopsActive
                    unAvailable.push({
                        ...allShopsObject[iterator],
                        shopId : iterator,
                    })
                    this.setState({
                        ...this.state,
                        unAvailableShopsActive : unAvailable
                    })
                }

                }
                
            })
        })
    }
    deg2rad = (deg) => {
        return deg * (Math.PI/180)
      }

    printActive = () => {
        // console.log(this.state.availableShopsActive)
    }

    render(){

        // console.log(this.state.availableShopsActive)
        // console.log(this.state.unAvailableShopsActive)
        

        return(
            <div className={classes.Container}>
                <NavbarAlt />
                {/* <button onClick={this.printActive}>Print Available Active Shops</button> */}
                <div className={classes.Main}>
                    <div className={classes.Left}>
                        <CategoryCardShops icon={food} text="Food Delivery" toLink="food" />
                        <CategoryCardShops icon={grocery} text="Groceries & Essentials" toLink="grocery" />
                        <CategoryCardShops icon={fish} text="Meat & Fish" toLink="fish_meat" />
                        {/* <CategoryCardShops icon={fruits} text="Fruits & Vegetables" toLink="fruits_veggies" /> */}
                        <CategoryCardShops icon={homeFood} text="Home Food" toLink="home" />
                        {/* <CategoryCardShops icon={fish} text="Fish & Meat" toLink="fish_meat" />
                        <CategoryCardShops icon={pet} text="Pet Supplies" toLink="pet" />
                        <CategoryCardShops icon={gift} text="Gift Shop" toLink="gift" />
                        <CategoryCardShops icon={meds} text="Medicine Stores" toLinkt="meds" />
                        <CategoryCardShops icon={apparels} text="Apparels" toLink="apparels" />
                        <CategoryCardShops icon={health} text="Health & Wellness" toLink="health" />
                        <CategoryCardShops icon={pkg} text="Package Pickup" toLink="package" />
                        <CategoryCardShops icon={taxi} text="Taxi Service" toLink="taxi" /> */}
                    </div>
                    <div className={classes.Right}>
                        <span className={classes.Heading}>Available Shops</span>
                        <div className={classes.AvailableList}>
                            {
                                this.state.availableShopsActive.map((iterator, index) => {
                                    if(this.props.activeCategory===iterator.Category) {
                                        {/* console.log('Iterator : ', iterator) */}
                                        return(
                                            <AvailableShopsCard icon={iterator.Doc7} discount="Upto 40% Off" {...iterator} available={false} key={index} />
                                        )
                                    }
                                })
                            }
                        </div>
                        <span className={classes.Heading}>UnAvailable Shops</span>
                        <div className={classes.AvailableList}>
                        {
                                this.state.unAvailableShopsActive.map((iterator, index) => {
                                    if(this.props.activeCategory===iterator.Category) {
                                        console.log('Iterator : ', iterator)
                                        return(
                                            <UnAvailableShopsCard icon = {iterator.Doc7} {...iterator} available={false} key={index} />
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        activeCategory : state.red.categoryDisplay,
        activeLocation : state.red.location,
        activeCity : state.red.city
    }
}


export default connect(mapStateToProps)(ShopsAvailable)