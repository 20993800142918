import React from 'react'
import classes from '../OrderTrack/OrderTrack.module.css'
import classesCon from '../OrderTrack/OrderTrackMain.module.css'
import Footer from '../Footer/Footer'
import GoogleMapReact from 'google-map-react'

import NavbarAlt from '../NavbarAlt/NavbarAlt'
import avatar from '../../assets/avatar2.jpg'
import phone from '../../assets/phone.svg'
import {database} from '../../firebase'
import { NavLink } from 'react-router-dom'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polyline
  } from "react-google-maps";

const AnyReactComponent = ({ text }) => <div className={classes.Marker}>{text}</div>;

// const InternalMap = props => (
//     <GoogleMap defaultZoom={7} defaultCenter={{ lat: -34.897, lng: 151.144 }}>
//       <Polyline
//         path={[{ lat: -34.397, lng: 150.644 }, { lat: -35.397, lng: 151.644 }]}
//       />
//     </GoogleMap>
//   );
  
//   const MapHoc = withScriptjs(withGoogleMap(InternalMap));
  
//   const MyMapComponent = props => (
//     <MapHoc
//       googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCnaq_nQX-2yYsEQHJifUmS2q3Je5N-f7cY&v=3.exp&libraries=geometry,drawing,places"
//       loadingElement={<div style={{ height: `100%` }} />}
//       containerElement={<div style={{ height: `400px` }} />}
//       mapElement={<div style={{ height: `100%` }} />}
//     />
//   )

class OrderTrack extends React.Component{
    state = {
        orderData: [],
        cartData: []
    }

    componentDidMount() {
        let trackingUrl = window.location.pathname.split("/")
        let orderNo = trackingUrl.pop()

        let comp = this

        console.log(orderNo)
        database.ref().child("Orders")
        .orderByChild("OrderNo").equalTo(orderNo)
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
                let orderData = {}
                let newCart = []
                snapshot.forEach(function(snapshot) {
                    snapshot.child("Cart").forEach(function(snapshot) {
                        let cartData = snapshot.val() 
                        newCart.push(cartData)
                    })
                    orderData = snapshot.val()
                })
                comp.setState({
                    orderData: orderData,
                    cartData: newCart
                })
            }
        })
    }
    render() {
    console.log('Order',this.state.orderData)
    let data = this.state.orderData

    const pathCoordinates = [
        { lat:  51.87924959999999, lng: -0.3767099 },
        { lat: 51.89624982316351, lng: -0.4286719858646393 }
    ];

    return(
        
        <div className={classesCon.Container}>
            <NavbarAlt />
            <div className={classesCon.Main}>
                <div className={classesCon.Contact}>
                    <div className={classesCon.InfoPanel}>
                        <div className={classesCon.Left}>
                            <div className={classes.Container}>

                            {data.Status === "1" ? <>
                                 <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Accepted</span>
                                </div>
                                 <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Order Being Processed</span>
                                </div>
                                 <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Delivery Executive has arrived</span>
                                </div>
                                  <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Order picked up</span>
                                </div>
                                  <div className={classes.ActiveTop}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Order Delivered</span>
                                </div> </> : null }
                                {data.Status === "2" ? <>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Accepted</span>
                                </div>
                                 <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Being Processed</span>
                                </div>
                                 <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Delivery Executive has arrived</span>
                                </div>
                                  <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Order picked up</span>
                                </div>
                                  <div className={classes.ActiveTop}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Order Delivered</span>
                                </div> </> : null }

                                {data.Status === "3" ? <>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Accepted</span>
                                </div>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Being Processed</span>
                                </div>
                                 <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Delivery Executive has arrived</span>
                                </div>
                                  <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Order picked up</span>
                                </div>
                                  <div className={classes.ActiveTop}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Order Delivered</span>
                                </div> </> : null }

                                {data.Status === "4" ? <>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Accepted</span>
                                </div>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Being Processed</span>
                                </div>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Delivery Executive has arrived</span>
                                </div>
                                  <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order picked up</span>
                                </div>
                                  <div className={classes.ActiveTop}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircle}></span></span>
                                    <span className={classes.OrderStatus}>Order Delivered</span>
                                </div> </> : null }

                                {data.Status === "5" ? <>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Accepted</span>
                                </div>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Being Processed</span>
                                </div>
                                <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Delivery Executive has arrived</span>
                                </div>
                                 <div className={classes.ActiveTop+' '+classes.Proceed}>
                                    <span className={classes.OuterCircle}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order picked up</span>
                                </div>
                                 <div className={classes.ActiveTop}>
                                    <span className={classes.OuterCircleActive}><span className={classes.InnerCircleActive}></span></span>
                                    <span className={classes.OrderStatus}>Order Delivered</span>
                                </div> </> : null }


                                {data.DeliveryName !== undefined ? <>
                                <div className={classes.HRLine}></div>

                                <div className={classes.Delivery}>
                                    <div className={classes.Left}>
                                        <div className={classes.Image}>
                                            <img src={data.DeliveryPP} alt="Avatar" />
                                        </div>
                                        
                                        <div className={classes.Profile}>
                                            <span className={classes.Name}>{data.DeliveryName}</span>
                                            <span className={classes.Role}>{data.DeliveryNumber}</span>
                                        </div>
                                    </div>
                                    <img className={classes.PhoneIcon} src={phone} alt="Phone Icon" />
                                </div> </> : null }

                                <div className={classes.HRLine}></div>
                                <div className={classes.Payment}>
                                    <div className={classes.Left}>
                                        <span className={classes.Paid}>Order Details</span>
                                    </div>
                                </div>
                                <div className={classes.Payment}>
                                    <div className={classes.Left}>
                                        <span className={classes.OrderStatus}>{data.Qty} Items £{data.Total}</span>
                                    </div>
                                    <span className={classes.Right}>
                                    <NavLink className={classes.SupportCustomer} to = "/customer-support">CONTACT SUPPORT</NavLink>
                                    </span>
                                </div>

                                {data.DeliverySelection !== "" ? <>
                                <div className={classes.HRLine}></div>
                                <div className={classes.Payment}>
                                    <div className={classes.Left}>
                                        <span className={classes.Paid}>Delivery Option</span>
                                    </div>
                                </div>

                                <div className={classes.Payment}>
                                <div className={classes.Left}>
                                        <span className={classes.OrderStatus}>Immediately</span>
                                    </div>
                                    <span className={classes.Right}> <p className={classes.SupportCustomer}>{data.DeliverySelection}</p></span>
                                </div> </> : null }
                                
                                <div className={classes.HRLine}></div>
                                <div className={classes.SectionTitle}>
                                    <h3>Billing Details</h3>
                                </div>


                                {this.state.cartData.map((iterator, index) => ( 
                                <div className={classes.SectionDetails}>
                                    <div className={classes.Left}>
                                        <span>{iterator.Name}</span><br></br>
                                        <span className={classes.ItemDescription}>x{iterator.Qty}</span>
                                    </div>
                                    <span className={classes.Right}>£{parseFloat(iterator.Total).toFixed(2)}</span>
                                </div>
                                ))}

                                <div className={classes.HRLine}></div>
                                <div className={classes.SectionDetails+' '+classes.SectionTotal}>
                                    <div className={classes.Row}>
                                        <div className={classes.Left}>
                                            <span>Item Total</span><br></br>
                                        </div>
                                        <span className={classes.Right}>£{parseFloat(data.Subtotal).toFixed(2)}</span>
                                    </div>
                                    <div className={classes.Row}>
                                        <div className={classes.Left}>
                                            <span>Service Charges</span><br></br>
                                        </div>
                                        <span className={classes.Right}>£{parseFloat(data.ServiceCharges).toFixed(2)}</span>
                                    </div>
                                    <div className={classes.Row}>
                                        <div className={classes.Left}>
                                            <span>Total Discount</span><br></br>
                                        </div>
                                        <span className={classes.Right}>£{parseFloat(data.Discount).toFixed(2)}</span>
                                    </div>
                                    <div className={classes.Row}>
                                        <div className={classes.Left}>
                                            <span>Delivery Charges</span><br></br>
                                        </div>
                                        <span className={classes.Right}>£{parseFloat(data.DeliveryCharges).toFixed(2)}</span>
                                    </div>

                                    <div className={classes.Row}>
                                        <div className={classes.Left}>
                                            <span>Surcharges</span><br></br>
                                        </div>
                                        <span className={classes.Right}>£{parseFloat(data.Surcharges).toFixed(2)}</span>
                                    </div>

                                    <div className={classes.Row}>
                                        <div className={classes.Left}>
                                            <span>Tips</span><br></br>
                                        </div>
                                        <span className={classes.Right}>£{parseFloat(data.Tips).toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className={classes.SectionDetails+' '+classes.SectionTotal}>
                                    <div className={classes.Row}>   
                                        <div className={classes.Left}>
                                            <span><b>Total Paid</b></span><br></br>
                                            {/* <span className={classes.ItemDescription}>Debit Card - XXXX-XXXX-XXXX-X456</span> */}
                                        </div>
                                        <span className={classes.Right}><b>£{parseFloat(data.Total).toFixed(2)}</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classesCon.Right}>
                        {
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyCnaq_nQX-2yYsEQHJifUmS2q3Je5N-f7c`' }}
                                    defaultCenter={{lat : 59.95, lng : 30.33}}
                                    defaultZoom={11}
                                >
                                    <AnyReactComponent
                                    lat={59.955413}
                                    lng={30.337844}
                                    text="Hurrys"
                                    />

                                      {/* <Polyline
                                   path={pathCoordinates}
                                   geodesic={true}
                                   options={{
                                       strokeColor: "#ff2527",
                                       strokeOpacity: 0.75,
                                       strokeWeight: 2,
                                       icons: [
                                           {
                                               icon: lineSymbol,
                                               offset: "0",
                                               repeat: "20px"
                                           }
                                       ]
                                   }}
                               /> */}
                                </GoogleMapReact>
                        }

                            {/* <GoogleMap
                               defaultZoom={this.props.zoom}
                               defaultCenter={this.props.center}
                           >
                               <Polyline
                                   path={pathCoordinates}
                                   geodesic={true}
                                   options={{
                                       strokeColor: "#ff2527",
                                       strokeOpacity: 0.75,
                                       strokeWeight: 2,
                                       icons: [
                                           {
                                               icon: lineSymbol,
                                               offset: "0",
                                               repeat: "20px"
                                           }
                                       ]
                                   }}
                               />
                           </GoogleMap>
                            <MyMapComponent />
                            <GoogleMap defaultZoom={7} defaultCenter={{ lat: -34.897, lng: 151.144 }}>

                            </GoogleMap> */}
   

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>       
    )
    }
}

export default OrderTrack