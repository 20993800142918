import React, {useState, useEffect} from 'react';
import CustomerHeader from '../CustomerHeader';
import styles from '../CustomerSupport.module.css';
import classes from './CustomerFAQ.module.css';
import { NavLink } from 'react-router-dom';
import { database } from '../../../firebase';


const  CustomerFAQ = ()  => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        fetchQuestions(); 
     },[]);


    const fetchQuestions = ()=>{
        setLoading(true);
        database.ref("/Masters/Faqs/").once('value').then(snapshot =>{
            const allQuestion = Object.values(snapshot.val());
            setLoading(false);
            setQuestions(allQuestion)
        }).catch((error)=>{
            setLoading(false);
        })
    }

    return (
        <div className = {styles.container}>
              <CustomerHeader/>
              
              <div className = {classes.ques_container}>
                  <div className = {classes.ques_box_area}>
                    {/* <NavLink to ="/faq-question">
                        <div className = {classes.ques_box}>
                            <p className = {classes.heading} >Question 1</p>
                            <p className = {classes.answer} >Answe1 with a big senetense tht will eventually go to the second line. Answe1 with a big senetense tht will eventually go to the second line…</p>
                        </div>

                    </NavLink> */}
                    {
                        loading ? (
                            <div>Loading ...</div>
                        ): (
                            questions.map((data, index) => (
                                <div className = {classes.ques_box}>
                                    <p className = {classes.heading} > {data.Question}</p>
                                    <p className = {classes.answer} >{data.Answer}</p>
                                </div>
                            ))
                        )
                    }
                  

                

                </div>
              </div>
        </div>
          
        
        
    )
}

export default CustomerFAQ
