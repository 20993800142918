import React, {useState, useEffect} from 'react'
import WalletCard from '../WalletCard/WalletCard'
import classes from './MyWallet.module.css'
import Pound from '../../assets/pound.svg'
import grocery from '../../assets/grocery-icon.svg'
import PlayStore from '../../assets/PlayStoreIcon.png'
import AppleStore from '../../assets/AppleStoreIcon.png'
import {withRouter} from 'react-router-dom';
import {database} from '../../firebase';


const MyWallet  = (props) => {
        const [balance, setBalance] = useState(0);
        const [transactions, setTransactions] = useState([]);

        useEffect(()=>{
            getBalance(); 
         },[]);

         const getBalance = () =>{
            const userId = localStorage.getItem("UserId");
            console.log("userId", userId);
            database.ref('/Users/'+userId+'/Wallet/').once('value').then(snapshot =>{
                setBalance(snapshot.val())
            });

            database.ref('/Users/'+userId+'/Transactions/').once('value').then(snapshot =>{
               
                setTransactions(snapshot.val())
                
            });
        }

    return(
        <div className={classes.Container}>
            {console.log(transactions)}
            <span className={classes.WalletHeading}>WALLET TRANSACTIONS</span>
            <div className={classes.Main}>
                <div className={classes.MainLeft}>
                    <p className={classes.MainLeftHeading}>Recents</p>
                    <div className={classes.HRLine}></div>

                    {Object.keys(transactions).reverse().map((iterator, index) => ( 
                    <div className={classes.WalletCards}>
                        {transactions[iterator].TransactionType == "Cr" ?
                        <WalletCard type="cashback" icon={Pound} title= {transactions[iterator].TransactionName} time= {transactions[iterator].Date} amount= {transactions[iterator].Amount} /> : 
                        <WalletCard type="transaction" icon={grocery} title= {transactions[iterator].TransactionName} time= {transactions[iterator].Date} amount= {transactions[iterator].Amount} /> }
                    </div>
                    ))}
                </div>
                <div className={classes.MainRight}>
                    <div className={classes.AvailableBalance}>
                        <span className={classes.Heading}>Available Balance</span>
                        <span className={classes.Balance}>￡{Number(balance).toFixed(2)}</span>
                    </div>
                    <div className={classes.Phone}>
                        <span className={classes.Heading}>Download the app for smoother experience and for features like wallet andd promocode usages</span>
                        <span className={classes.SubHeading}>Get things done right from your phone</span>
                        <img src={PlayStore} onClick={() => { window.open('https://play.google.com/store/apps/details?id=hurrys.corp.ltd', '_blank')}} alt="Play Store Icon" />
                        <img src={AppleStore} onClick={() => { window.open('https://apps.apple.com/in/app/hurrys-customer-app/id1529889480', '_blank')}} alt="Apple Store Icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MyWallet)