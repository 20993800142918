import React, {useState , useEffect} from 'react'
import ActiveOrderCard from '../ActiveOrderCard/ActiveOrderCard'
import PastOrderCard from '../PastOrderCard/PastOrderCard'
import classes from './ProfileOrders.module.css'
import {database} from '../../firebase';


const ProfileOrders = (props) => {

    const [activeOrders, setActiveOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pastOrder, setPastOrder] = useState([]);

    useEffect(()=>{
       getActiveOrder(); 
    },[]);

    const getActiveOrder = () =>{
        setLoading(true);
        const userId = localStorage.getItem("UserId");
        database.ref('/Orders/').orderByChild("UserId").equalTo(userId).once('value').then(snapshot =>{
            setLoading(false);
            const inActive = ["5","10", "1000"];
            const active = ["1", "2", "3","4"];
            const allOrders = Object.values(snapshot.val())
            const allActiveOrder = allOrders.filter((orders => active.includes(orders.Status)));
            const allPastOrder = allOrders.filter((orders => inActive.includes(orders.Status)));
            setActiveOrders(allActiveOrder);
            setPastOrder(allPastOrder)
        })
        .catch((error)=>{
            setLoading(false);
        })
    }
    return(
        <div className={classes.Container}>
            <div className={classes.Orders}>
                <span className={classes.OrdersHeading}>ACTIVE ORDERS</span>
                <div className={classes.OrdersCards}>
                    {
                        loading ? (
                            <div> Loading.....</div>
                        ) :
                        (
                            activeOrders.map((order,index)=> (
                                <ActiveOrderCard key={index} activeOrder={order} />
                            ))
                        )
                    }
                </div>
            </div>       
            <div className={classes.Orders}>
                <span className={classes.OrdersHeading}>PAST ORDERS</span>
                <div className={classes.OrdersCards}>
                    {
                        pastOrder.map((order,index)=> (
                            <PastOrderCard key={index} pastOrder={order} />
                        ))
                    }
                    
                </div>
            </div>   
        </div>
    )
}

export default ProfileOrders