import React from 'react'
import Cart from '../Cart/Cart'
import CartCard from '../Cart/CartCard/CartCard'
import Footer from '../Footer/Footer'
import _ from "lodash";
import NavbarAlt from '../NavbarAlt/NavbarAlt'
import classes from './Checkout.module.css'
// import classes1 from '../AddressSelector/AddressSelector.module.css';
// import classes2 from '../PaymentAccordion/PaymentAccordion.module.css';
import haldirams from '../../assets/haldirams.jpeg'
import note from '../../assets/note.png'
import deliveryLocation from '../../assets/deliveryLocation.svg'
import PaymentAccordion from '../PaymentAccordion/PaymentAccordion'
import AddressSelector from '../AddressSelector/AddressSelector'
import {connect} from 'react-redux'
import { getDistance } from 'geolib'
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap} from "react-google-maps"
import {database} from '../../firebase'
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import config from '../../config/config'
import { Elements, PaymentElement, ElementsConsumer} from '@stripe/react-stripe-js';
import qs from "qs";
// import debitCard from '../../assets/debitCard.svg'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function PaymentGateway() {
    const handleClick = async (event) => {
        // Get Stripe.js instance
        const stripe = await stripePromise;
    
        // Call your backend to create the Checkout Session
        const response = await axios.post('https://liveyumfoods.xyz/StripeTest/public/paymentorders.php?amount=100');
        console.log(response)
    
        const session = await response.data
        console.log(session)

        // const session = await stripe.checkout.sessions.create({
        //     payment_method_types: ['card'],
        //     line_items: [
        //       {
        //         price_data: {
        //           currency: 'usd',
        //           product_data: {
        //             name: 'T-shirt',
        //           },
        //           unit_amount: 2000,
        //         },
        //         quantity: 1,
        //       },
        //     ],
        //     mode: 'payment',
        //     success_url: 'https://example.com/success',
        //     cancel_url: 'https://example.com/cancel',
        //   });

        //   console.log(session)

    
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session,
        });

        console.log(result)
    
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      };

    return (
        <div>
            <div className={classes.DeliveryControls}>
            <button className={classes.AddressContinue} role="link" onClick={handleClick}>PROCEED TO PAY</button>
            </div>
        </div>
    );
  }

const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCnaq_nQX-2yYsEQHJifUmS2q3Je5N-f7c&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `0` }} />,
    containerElement: <div style={{ height: `0` }} />,
    mapElement: <div style={{ height: `0` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new window.google.maps.DirectionsService();
      const shopAddressArray = this.props.activeShopLocation.split(',')
      const shopLat = shopAddressArray[0]
      const shopLng = shopAddressArray[1]
      const userLat = this.props.userCurrentAddress.latitude
      const userLng = this.props.userCurrentAddress.longitude
        console.log('LAT AND LONG DETAILS : ', shopLat, shopLng, userLat, userLng)
      DirectionsService.route({
        origin: new window.google.maps.LatLng(shopLat, shopLng),
        destination: new window.google.maps.LatLng(userLat, userLng),
        travelMode: window.google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
            console.log('Result of direction request : ', result.routes[0].legs[0].distance.value)
            console.log('Props in maps : ', this.props)
            const distance = result.routes[0].legs[0].distance.value
            const d = this.props.d
            let deliveryCost = 0

            console.log("Distance", distance)
            console.log("D: ", d)


            if(distance>d) {
                deliveryCost = (((distance/1000)-d) * 0.5).toFixed(2)
                console.log('Delivery Cost : ', deliveryCost)
            }
            else if(distance<=d) {
                deliveryCost = 0
                console.log('Delivery Cost : ', deliveryCost)
            }
            if(Number(deliveryCost < 0)) {
                this.props.setDeliveryCost(2.5)
              } else {
                this.props.setDeliveryCost(deliveryCost)
              }
        //   this.setState({
        //     directions: result.routes[0].legs[0].distance.value
        //   });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    },
    componentDidUpdate(){
        console.log('Props updated')
        const DirectionsService = new window.google.maps.DirectionsService();
      const shopAddressArray = this.props.activeShopLocation.split(',')
      const shopLat = shopAddressArray[0]
      const shopLng = shopAddressArray[1]
      const userLat = this.props.userCurrentAddress.latitude
      const userLng = this.props.userCurrentAddress.longitude
        console.log('LAT AND LONG DETAILS : ', shopLat, shopLng, userLat, userLng)
      DirectionsService.route({
        origin: new window.google.maps.LatLng(shopLat, shopLng),
        destination: new window.google.maps.LatLng(userLat, userLng),
        travelMode: window.google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
            console.log('Result of direction request : ', result.routes[0].legs[0].distance.value)
            console.log('Props in maps : ', this.props)
            const distance = result.routes[0].legs[0].distance.value
            const d = this.props.d
            let deliveryCost = 0

            console.log("Distance", distance)
            console.log("D: ", d)

            if(distance>d) {
                deliveryCost = (((distance/1000)-d) * 0.5).toFixed(2)
                console.log('Delivery Cost : ', deliveryCost)
            }
            else if(distance<=d) {
                deliveryCost = 0
                console.log('Delivery Cost : ', deliveryCost)
            }
            
            if(Number(deliveryCost < 0)) {
                this.props.setDeliveryCost(2.5)
              } else {
                this.props.setDeliveryCost(deliveryCost)
              }
        //   this.setState({
        //     directions: result.routes[0].legs[0].distance.value
        //   });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  })
)(props => {
    
    return(
        <React.Fragment></React.Fragment>
    )
}
    
);

const CheckoutForm = ({ stripe, elements, proceedToPay, grandTotal}) => {
    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.origin+"/profile-orders",
        },
      });
  
  
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        proceedToPay();
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <div className={classes.DeliveryControls}>
            <button disabled={!stripe} className={classes.AddressContinue}>PROCEED TO PAY £{grandTotal}</button>
        </div>
      </form>
    )
  };
class Checkout extends React.Component{
    state = {
        deliveryOpen : true,
        showAddressModal : false,
        show : true,
        address : this.props.user.address,
        modalAddress : null,
        distance : 0,
        grandTotal: 0,
        serviceCharges: 0,
        surcharges: 0,
        tips: 0.00,
        discount: 0.00,
        instructionsCooking: '',
        instructionsDelivery: '',
        deliveryOption: '',
        cart: [],
        totalQty: '',
        cartTotal: 0.00,
        clientSecret: "",
        wallet: 0.00,
        addressIndex: -1,
        showPaymentGateway: false
    }

    handleDeliveryAccordion = () => {
        this.setState({
            ...this.state,
            deliveryOpen : !this.state.deliveryOpen
        })
    }

    modalClose = () => {
        this.setState({
            ...this.state,
            showAddressModal : false
        })
    }



    modalAddress = (lat, lng, add) => {
        console.log('Modal address : ', lat, lng, add)
        this.setState({
            ...this.state,
            modalAddress : {
                latitude : lat,
                longitude : lng,
                address : add
            }
        }, () => {
            console.log('State modal address ', this.state.modalAddress)
            this.props.pushAddress({
                latitude : lat,
                longitude : lng,
                address : add
            })
            const LocationArray = this.props.red.selectedShop.Location.split(',')
            const shopLat = LocationArray[0]
            const shopLng = LocationArray[1]
            const userLat = this.props.user.address[this.props.user.address.length - 1].latitude
            const userLng = this.props.user.address[this.props.user.address.length - 1].longitude

            const distancce = getDistance(
                { latitude: shopLat, longitude: shopLng },
                { latitude: userLat, longitude: userLng }
            );
            console.log('Distance is : ', distancce)

            this.setState({
                addressIndex: this.props.user.address.length -1
            })
        })
    }
    
    addAddress = () => {
        this.setState({
            ...this.state,
            showAddressModal : true
        })
    }

    showAddress = () => {
        console.log('User address : ', this.props.user.address)
        this.props.user.address.map((iterator) => {
            console.log('Iterating : ', iterator)
        })
    }

    deg2rad = (deg) => {
        return deg * (Math.PI/180)
      }
    
    async componentDidUpdate(prevProps,prevState){
        if ((!_.isEqual(prevState.grandTotal, this.state.grandTotal) || !_.isEqual(prevProps.user.deliveryCost,this.props.user.deliveryCost))
         && this.state.grandTotal >0 && Number(this.props.user.deliveryCost) >0) {
            const total = (((Number(this.state.cartTotal)+Number(this.state.serviceCharges)+Number(this.state.tips)+Number(this.state.surcharges)+Number(this.props.user.deliveryCost))-Number(this.state.discount)) * 100).toFixed(0)
            console.log(total);
            const paymentIntent = await axios.post('https://api.stripe.com/v1/payment_intents', 
            qs.stringify({  amount: total, currency: "eur", automatic_payment_methods: { enabled: true } }) , { 
            headers: {
                'Authorization': `Bearer ${config.STRIPE_SECRET_KEY}`
            }});
            this.setState({ clientSecret: paymentIntent.data.client_secret });
        }
        
    }

    async componentDidMount(){
        let comp = this;
        let grandTotal = 0;

        comp.setState({
            addressIndex: comp.props.user.address.length -1
        })


        database.ref().child("Masters")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
                let sur = 0
                if(snapshot.val().Surcharges === "Yes") {
                    sur = (parseFloat(snapshot.val().SurchargesAmount)).toFixed(2)
                } else {
                    sur = 0
                }

                grandTotal += Number(sur);

                comp.setState({
                    surcharges: sur,
                })
            }
        })

        database.ref("Users").child(comp.props.user.UserId).child("Wallet")
        .on("value", snapshot => {
            if(snapshot.val() != undefined){
                comp.setState({
                    wallet: snapshot.val()
                })
            }
        })

        database.ref("Users").child(comp.props.user.UserId).child('Cart')
        .on('value', snapshot => {
            if(snapshot.val()){
                let items = []
                let itemQuantity = 0
                let total = 0
                snapshot.forEach(function(snapshot) {
                    itemQuantity = itemQuantity + parseInt(snapshot.val().Qty)
                    total = (parseFloat(total) + parseFloat(snapshot.val().Total)).toFixed(2)
                    items.push(snapshot.val())
                })
                grandTotal = Number(total);

                        comp.setState({
                            cart : items,
                            totalQty: itemQuantity,
                            cartTotal: total,
                        })
                

            database.ref().child("Masters").child("ServiceCharges").on("value", function(snapshot) {
                if(snapshot.exists()) {
                    let charge = snapshot.val()
                    let serviceCharges = (parseFloat(total) * (parseFloat(charge) / parseFloat(100))).toFixed(2)
                    comp.setState({
                        serviceCharges: serviceCharges,
                        grandTotal: (Number(grandTotal) + Number(serviceCharges)).toFixed(2),
                    })
                }
            })
        }
            else {
                comp.setState({
                    cart : [],
                    totalQty: 0,
                    cartTotal: 0,
                    serviceCharges: 0
                })
            }
        })

    }

    handleTips(tipValue) {
        console.log("Inside Tips")
        let tips = 0
        if(tipValue === "1") {
            tips = 1
        } 
        else if(tipValue === "2") {
            tips = 2
        } 
        else if(tipValue === "3") {
            tips = 3
        } 
        else if(tipValue === "4") {
            tips = 4
        }
        else tips = 0

        let deliveryCost = this.props.user.deliveryCost

        this.setState({
            tips: parseFloat(tips).toFixed(2),
            grandTotal: ((Number(this.state.cartTotal) + Number(this.state.serviceCharges) + Number(deliveryCost) + Number(this.state.surcharges) + Number(tips)) - Number(this.state.discount)),
        })
    }

    handleTipsChange(e) {
        const value = e.target.value
        let deliveryCost = this.props.user.deliveryCost

        console.log(value)
        if(value !== '') {
            this.setState(() => ({
                tips: parseFloat(value).toFixed(2),
                grandTotal: ((Number(this.state.cartTotal) + Number(this.state.serviceCharges) + Number(deliveryCost) + Number(this.state.surcharges) +Number(parseFloat(value).toFixed(2))) - Number(this.state.discount)),
            }))
        } else {
            this.setState(() => ({
                tips: parseFloat("0").toFixed(2),
                grandTotal: ((Number(this.state.cartTotal) + Number(this.state.serviceCharges) + Number(deliveryCost) + Number(this.state.surcharges) +Number(parseFloat("0").toFixed(2))) - Number(this.state.discount)),
            }))
        }

    }

    handleChange(e) {
        const value = e.target.value
        this.setState({
            [e.target.name]: value
        })
    }

    handleDeliveryChange(e) {
        const value = e.target.value
        if(value === "Self PickUp") {
            this.setState((state) => ({
                deliveryOption: value,
                grandTotal: state.grandTotal - Number(state.tips),
                tips: 0
            }))
        } else {
            this.setState({
                [e.target.name]: value,
            })
        }
    }

    proceedToPay() {
        console.log(this.props)
        let data = this.state

        let address = "";
        let lat = ""
        let lng = ""

        console.log(this.props.user.address)

        for (var i = 0; i < this.props.user.address.length; i++) {
            if(Number(i) == Number(this.state.addressIndex)) {
                address = this.props.user.address[i].address
                lat = this.props.user.address[i].latitude
                lng = this.props.user.address[i].longitude
            }
        }

        if(parseFloat(this.state.cartTotal) <= 0) {
            alert("Please Add items to the cart")
            return
        }


        if(data.deliveryOption === '') {
            alert("Choose Your Delivery")
            return
        }

        // if(data.deliveryOption === '') {
        //     alert("Choose Your Delivery")
        //     return
        // }


        if(window.confirm("Are you sure want to place the order")) {

    
          var date = new Date()
          var todayDate = formatDate(date)
        
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
          "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
          var orderDateTime = ("0" + date.getDate()).slice(-2) + ','+ monthNames[date.getMonth()] + ' ' +date.getFullYear() + ' '
            + date.getHours() + ':' + date.getMinutes()
        
          var epchTime = Date.parse(date)

          console.log(this.props.user)

          console.log(data.cart)
          console.log(data.totalQty)

          let total = (Number(this.state.cartTotal)+Number(this.props.user.deliveryCost)+Number(this.state.serviceCharges)+Number(this.state.surcharges)+Number(this.state.tips)-Number(this.state.discount)).toFixed(2)
          console.log(total)

          var items = ""
          for(let i = 0; i< data.cart.length; i++) {
              items = items + data.cart[i].Name + ":" + data.cart[i].Qty
          }

        var orderRef = database.ref().child("Orders").push()
        orderRef.child("Address").set(address)
        orderRef.child("CName").set(this.props.user.Name)
        orderRef.child("DeliveryCharges").set(this.props.user.deliveryCost)
        orderRef.child("DeliveryDate").set(String(todayDate))
        orderRef.child("DeliveryInstruction").set(data.instructionsDelivery)
        orderRef.child("DeliveryPrice").set(this.props.user.deliveryCost)
        orderRef.child("DeliverySelection").set(data.deliveryOption)
        orderRef.child("DeliveryTime").set("Immediately")
        orderRef.child("Discount").set(this.state.discount)
        orderRef.child("Distance").set("0")
        orderRef.child("Flat").set("1")
        orderRef.child("Items").set(items)
        orderRef.child("ItemDetails").set(items)
        orderRef.child("LocationCoordinates").set(String(lat), String(lng))
        orderRef.child("Number").set(this.props.user.MobileNumber)
        orderRef.child("OrderDate").set(String(todayDate))
        orderRef.child("OrderDateTime").set(String(orderDateTime))
        orderRef.child("OrderNo").set(String(epchTime))
        orderRef.child("OrderType").set("General")
        orderRef.child("Packing").set("0")
        orderRef.child("Payment").set("Online")
        orderRef.child("PaymentId").set("")
        orderRef.child("PushId").set(orderRef.getKey())
        orderRef.child("Qty").set(String(data.totalQty))
        orderRef.child("Referred").set("")
        orderRef.child("ReferredUserId").set("")
        orderRef.child("Seller").set(this.props.red.selectedShop.UserId)
        orderRef.child("SellerCity").set(this.props.red.selectedShop.CityName)
        orderRef.child("SellerCommission").set(this.props.red.selectedShop.Commission)
        orderRef.child("SellerLoc").set(this.props.red.selectedShop.Location)
        orderRef.child("SellerLocality").set(this.props.red.selectedShop.Location)
        orderRef.child("SellerName").set(this.props.red.selectedShop.StoreName)
        orderRef.child("SellerStatus").set(this.props.red.selectedShop.Status)
        orderRef.child("Status").set("1")
        orderRef.child("Subtotal").set(this.state.cartTotal)
        orderRef.child("Taxes").set(String(parseFloat(data.serviceCharges) + parseFloat(data.surcharges).toFixed(2)))
        orderRef.child("Tips").set(String(data.tips))
        orderRef.child("Total").set(String(total))
        orderRef.child("UserId").set(this.props.user.UserId)
        orderRef.child("VendorIntructions").set(data.instructionsCooking)
        orderRef.child("Wallet").set("0")
        orderRef.child("ServiceCharges").set(data.serviceCharges)
        orderRef.child("Surcharges").set(data.surcharges)

        for(let i = 0; i< data.cart.length; i++) {
            var cartRef = orderRef.child("Cart").child(data.cart[i].PushId)
            cartRef.set(data.cart[i])
        }


        database.ref('/Users').child(this.props.user.UserId).child('Cart').set(null)

        alert("Thank You. Order Placed Successfully!!")

        window.location.assign("/profile-orders")


        } else {}

    }

    // changeAddress(e, index) {
    //     this.setState({
    //         addressIndex: index
    //     })
    // }

    changeAddress(index) {
        console.log("Index", index)
        this.setState({
            ...this.state,
            addressIndex : index
        })

        console.log("props", this.props.user.address[index].latitude)

        const DirectionsService = new window.google.maps.DirectionsService();
        const shopAddressArray = this.props.red.selectedShop.Location.split(',')
        const shopLat = shopAddressArray[0]
        const shopLng = shopAddressArray[1]
        const userLat = this.props.user.address[index].latitude
        const userLng = this.props.user.address[index].longitude
          console.log('LAT AND LONG DETAILS : ', shopLat, shopLng, userLat, userLng)
        DirectionsService.route({
          origin: new window.google.maps.LatLng(shopLat, shopLng),
          destination: new window.google.maps.LatLng(userLat, userLng),
          travelMode: window.google.maps.TravelMode.DRIVING,
        }, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
              console.log('Result of direction request : ', result.routes[0].legs[0].distance.value)
              console.log('Props in maps : ', this.props)
              const distance = result.routes[0].legs[0].distance.value
              const d = this.props.red.selectedShop.d
              let deliveryCost = 0
  
              console.log("Distance", distance)
              console.log("D: ", d)
  
              if(distance>d) {
                  deliveryCost = (((distance/1000)-d) * 0.5).toFixed(2)
                  console.log('Delivery Cost : ', deliveryCost)
              }
              else if(distance<=d) {
                  deliveryCost = 0
                  console.log('Delivery Cost : ', deliveryCost)
              }
              if(Number(deliveryCost < 0)) {
                this.props.setDeliveryCost(2.5)
              } else {
                this.props.setDeliveryCost(deliveryCost)
              }
          //   this.setState({
          //     directions: result.routes[0].legs[0].distance.value
          //   });
          } else {
            console.error(`error fetching directions ${result}`);
          }
        });
    }

    showPaymentGatewayModal = () => {
        this.setState({
            showPaymentGateway: true
        })
    }

    closePaymentGatewayModal = () => {
        this.setState({
            showPaymentGateway: false
        })
    }

    render(){
        return(
            <div className={classes.Container}>
                <NavbarAlt />
                    {
                        this.state.showAddressModal ? 
                        <AddressSelector modalClose={this.modalClose} modalAddress={this.modalAddress} />
                        : null
                    }



                <MapWithADirectionsRenderer
                    d={this.props.red.selectedShop.d}
                    setDeliveryCost={this.props.setDeliveryCost}
                    activeShopLocation = {this.props.red.selectedShop.Location}
                    userCurrentAddress = {this.props.user.address[this.props.user.address.length - 1]}
                /> 

                <div className={classes.Main}>
                    <div className={classes.Left}>
                        <div className={classes.Accordion} >
                            <div className={classes.AccordionTop} onClick={this.handleDeliveryAccordion}>
                                <div className={classes.AccordionTopText}>
                                    <img src={deliveryLocation} alt="Delivery Icon" />
                                    <span>Delivery Address</span>
                                </div>
                                <ion-icon name={this.state.deliveryOpen ? "chevron-up-sharp" : "chevron-down-sharp"}></ion-icon>
                            </div>
                            <div className={this.state.deliveryOpen ? `${classes.AccordionDrop} ${classes.AccordionDropExpand}` : `${classes.AccordionDrop}`}>
                            {
                                this.props.user.address.map((iterator, index) => {
                                    console.log('Checked ? ', index, this.props.user.address-1 )
                                    console.log('Address ? ', this.props.user.address )
                                    console.log('Address Index ? ', this.state.addressIndex )
                                    return(
                                        <div className={classes.AddressDropItem}>
                                            <div className={classes.AddressDropItemLeft}>
                                                <input type="checkbox" onChange = {this.changeAddress.bind(this, index)} checked={index=== this.state.addressIndex? true : false} />
                                                <div className={classes.AddressDropMiddle}>
                                                    <span className={classes.AddressDropDetail}>{iterator.address}</span>
                                                </div>
                                            </div>
                                            {/* <button type="button">EDIT</button> */}
                                        </div>
                                    )
                                })
                            }


                                <div className={classes.DeliveryControls}>
                                    <button className={classes.AddressAdd} onClick={this.addAddress} >+ ADD NEW ADDRESS</button>
                                    {/* <button className={classes.AddressContinue}>CONTINUE</button> */}
                                </div>

                            </div>
                        </div>

                        <div className={classes.HRLine}></div>
                        <PaymentAccordion />

                        {this.state.clientSecret !== "" &&
                        <div className={classes.paymentCard}>
                            <Elements stripe={stripePromise} options={{ clientSecret: this.state.clientSecret }}>
                                <ElementsConsumer>
                                    {({elements, stripe}) => (
                                        <CheckoutForm stripe={stripe} grandTotal = {((Number(this.state.cartTotal)+Number(this.state.serviceCharges)+Number(this.state.tips)+Number(this.state.surcharges)+Number(this.props.user.deliveryCost))-Number(this.state.discount)).toFixed(2)}  elements={elements} proceedToPay={this.proceedToPay} />
                                    )}
                                </ElementsConsumer>
                            </Elements>
                        </div>}
                       
                        {/* <div className={classes.DeliveryControls}>
                            <button onClick = {this.showPaymentGatewayModal} className={classes.AddressContinue}>PROCEED TO PAY £{((Number(this.state.cartTotal)+Number(this.state.serviceCharges)+Number(this.state.tips)+Number(this.state.surcharges)+Number(this.props.user.deliveryCost))-Number(this.state.discount)).toFixed(2)}</button>
                        </div>

                        {this.state.showPaymentGateway ?
                            <div className={classes1.Container}>
                                <div className={classes1.Backdrop}></div>
                                <div className={classes1.Main}>
                                    <ion-icon name="close-outline" onClick={this.closePaymentGatewayModal}></ion-icon>
                                    <div className={classes1.AddressBar}>
                                        {this.state.clientSecret !== "" &&
                                            <div className={classes.paymentCard}>
                                                <Elements stripe={stripePromise} options={{ clientSecret: this.state.clientSecret }}>
                                                    <ElementsConsumer>
                                                        {({elements, stripe}) => (
                                                            <CheckoutForm stripe={stripe} elements={elements} grandTotal = {((Number(this.state.cartTotal)+Number(this.state.serviceCharges)+Number(this.state.tips)+Number(this.state.surcharges)+Number(this.props.user.deliveryCost))-Number(this.state.discount)).toFixed(2)} proceedToPay={this.proceedToPay} />
                                                        )}
                                                    </ElementsConsumer>
                                                </Elements>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        : null } */}



                    </div>
                    <div className={classes.VerticalLine}></div>
                    <div className={classes.Right}>
                        <CartCard showTotal={false} />

                        <div className={classes.Tip}>
                            <span className={classes.TipHeading}>Choose Your Delivery</span>
                            <div className={classes.Option}>
                                <span><input type="radio" id="homeDelivery" onChange = {this.handleDeliveryChange.bind(this)} name="deliveryOption" value="Home Delivery" />&nbsp;<label for="homeDelivery">Home Delivery</label></span>
                                <span><input type="radio" id="selfPickUp" onChange = {this.handleDeliveryChange.bind(this)} name="deliveryOption" value="Self PickUp" />&nbsp;<label for="selfPickUp">Self Pickup</label></span>
                            </div>
                        </div>
                        <br />
                        <div className={classes.Comment} style={{borderBottom:0}}>
                            <img src={note} width="20" alt="comment"/><input type = "text" name = "instructionsCooking"  onChange = {this.handleChange.bind(this)} placeholder="Any instructions for cooking ? Eg: More Sauce, Less Spicy" />
                        </div>
                        <div className={classes.Comment}>
                            <img src={note} width="20" alt="comment"/><input type = "text" name = "instructionsDelivery" onChange = {this.handleChange.bind(this)}  placeholder="Any instructions for delivery ? Eg: Don't Ring Bell" />
                        </div>
                        <div className={classes.HRLine}></div>

                        <div className={classes.Tip}>
                            <span className={classes.TipHeading}>Wallet: £{parseFloat(this.state.wallet).toFixed(2)}</span> <br />
                            <span className={classes.ItemTotalText} style = {{color: "Red"}}>Wallet Points can be redeemed using the mobile app</span>
                        </div>



                    {console.log(this.state.deliveryOption)}

                        <div className={classes.Tip}>
                            {this.state.deliveryOption === "Home Delivery" ? <>
                            <span className={classes.TipHeading}>Tip your delivery executive</span>
                            <span className={classes.TipText}>Thank your delivery executive in these tough times by paying a small amount to help</span>
                            <div className={classes.TipButtons}>
                                <button className={classes.TipButton} onClick = {this.handleTips.bind(this, "1")}>£1</button>
                                <button className={classes.TipButton} onClick = {this.handleTips.bind(this, "2")}>£2</button>
                                <button className={classes.TipButton} onClick = {this.handleTips.bind(this, "3")}>£3</button>
                                <button className={classes.TipButton} onClick = {this.handleTips.bind(this, "4")}>£4</button>
                                <input className={classes.TipInput} type = "number" name = "custom" onChange = {this.handleTipsChange.bind(this)} placeholder="Custom" />
                            </div>
                            </>: null }

                            <div className={classes.OrderTotal}>
                                <div className={classes.ItemTotal}>
                                    <span className={classes.ItemTotalText}>Item Total</span>
                                    <span className={classes.ItemTotalTotal}>£{parseFloat(this.state.cartTotal).toFixed(2)}</span>
                                </div>

                                <div className={classes.ItemOther}>
                                    <span className={classes.ItemTotalText}>Service Charges</span>
                                    <span className={classes.ItemTotalTotal}>£{parseFloat(this.state.serviceCharges).toFixed(2)}</span>
                                </div>

                                <div className={classes.ItemOther}>
                                    <span className={classes.ItemTotalText}>Total Dicount</span>
                                    <span className={classes.ItemTotalTotal}>£{parseFloat(this.state.discount).toFixed(2)}</span>
                                </div>

                                <div className={classes.ItemOther}>
                                    <span className={classes.ItemOtherText}>Delivery Charges</span>
                                    <span className={classes.ItemOtherTotal}>£{parseFloat(this.props.user.deliveryCost).toFixed(2)}</span>
                                </div>

                                <div className={classes.ItemOther}>
                                    <span className={classes.ItemTotalText}>Surcharges</span>
                                    <span className={classes.ItemTotalTotal}>£{parseFloat(this.state.surcharges).toFixed(2)}</span>
                                </div>

                                <div className={classes.ItemOther}>
                                    <span className={classes.ItemTotalText}>Tips</span>
                                    <span className={classes.ItemTotalTotal}>£{parseFloat(this.state.tips).toFixed(2)}</span>
                                </div>
                    {/* <Directions /> */}

                                {/* <div className={classes.ItemOther}>
                                    <span className={classes.ItemOtherText}>Packing Charges</span>
                                    <span className={classes.ItemOtherTotal}>£12</span>
                                </div> */}
                            </div>

                            <div className={classes.FinalTotal}>
                                <span className={classes.FinalTotalHeading}>Total Payable</span>
                                <span className={classes.FinalTotalTotal}>£ {((Number(this.state.cartTotal)+Number(this.state.serviceCharges)+Number(this.state.tips)+Number(this.state.surcharges)+Number(this.props.user.deliveryCost))-Number(this.state.discount)).toFixed(2)}</span>
                            </div>
                        </div>
                        <br />
                        {/* <div className={classes.DeliveryControls}> */}
                            {/* <PaymentGateway /> */}
                            {/* <div className={classes.DeliveryControls}>
                                <button className={classes.AddressContinue} onClick={this.proceedToPay.bind(this)}>PROCEED TO PAY</button>
                            </div> */}
                            {/* <button className={classes.AddressContinue} onClick = {this.proceedToPay.bind(this)}>PROCEED TO PAY</button> */}
                        {/* </div> */}
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user : state.usr, 
        red : state.red
    }
}

const mapDispatchToProps = dispatch => {
    return{
        pushAddress : (address) => dispatch({
            type : 'PUSH_ADDRESS',
            payload : address
        }),
        setDeliveryCost : (cost) => dispatch({
            type : 'SET_DELIVERY_COST',
            payload : cost
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)