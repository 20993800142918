import React from 'react'
import classes from './NavbarAlt.module.css'
import Logo from '../../assets/LogoBlack.png'
import LocationIcon from '../../assets/location.svg'
import DownIcon from '../../assets/chevron.svg'
import UserIcon from '../../assets/user.svg'
import CartIcon from '../../assets/cart.svg'
import Cart from '../Cart/Cart'
import CartGreenIcon from '../../assets/cart_green.svg'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import ProfileGreen from '../../assets/profileGreen.svg'
import ProfileDrawer from '../ProfileDrawer/ProfileDrawer'
import {database} from '../../firebase'


class NavbarAlt extends React.Component{
    state={
        showCart : false,
        showProfile : false,
        count : 0,
        searchVend: ''
    }

    handleCart = () => {
        // console.log("Inside cart")
        // console.log(this.props.location.generalAddress)
        // if(this.props.location.generalAddress == undefined) {
        //     alert("Select Address At Home Page")
        //     return
        // }

        console.log(this.props.red.selectedShop)

        if(this.props.red.selectedShop == undefined) {
            alert("Please Add Items to Cart")
            return
        }

        this.setState({
            ...this.state,
            showCart : !this.state.showCart
        })
    }
    handleProfile = () => {
        var loggedIn = localStorage.getItem("LoggedIn")
        if(loggedIn) {
        this.setState({
            ...this.state,
            showProfile : !this.state.showProfile
        })
    } else {
        alert("Please login to view profile")
        window.location.assign("/")
        return
        }
    }

    componentDidMount() {
        if(this.props.user.UserId){
            database.ref('/Users').child(this.props.user.UserId).child('Cart').on('value', snapshot => {
                this.setState({
                    count : snapshot.numChildren()
                })
            });
        }
    }

    handleChange = (e) => {
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    render() {
        // console.log("Props", window.location.pathname)
        return(
            <React.Fragment>
                <div className={classes.Container}>
                    <div className={classes.Left}>
                        <NavLink className={classes.Logo} to="/">
                            <img src={Logo} alt="Logo" />
                        </NavLink>
                        <div className={classes.Location}>
                            <img src={LocationIcon} alt="Location Icon" />
                            <span className={classes.Text}>{this.props.location.generalAddress ? this.props.location.generalAddress : 'Get Location'}</span>
                            <img className={classes.Chevron} src={DownIcon} alt="Dropdown" />
                        </div>

                        {window.location.pathname == "/shops" ?
                        <input className={classes.Search} id = "searchVend" value = {this.state.searchVend} onChange = {this.handleChange} name = "searchVend" placeholder="Search for restaurants, shops, brands, items etc." /> : null }

                        {window.location.pathname == "/categories" ?
                       <input className={classes.Search} id = "searchShop" value = {this.state.searchShop} onChange = {this.handleChange} name = "searchShop" placeholder="Search for restaurants, shops, brands, items etc." /> : null }
                    </div>

                    <div className={classes.Right}>
                        {
                            this.state.showProfile ? 
                            <img className={classes.User} src={ProfileGreen} onClick={this.handleProfile} alt="User" /> : 
                            <img className={classes.User} src={UserIcon} alt="User" onClick={this.handleProfile} />
                        }
                        <div className={classes.cart} onClick={this.handleCart}>
                        {
                            this.state.showCart ? 
                            <div className={classes.Cart}>
                                <img className={classes.Cart} src={CartGreenIcon} alt="Cart" />
                            </div>
                                :
                            <div className={classes.Cart}>
                                <img className={classes.Cart} src={CartIcon} alt="Cart"  />
                            </div>
                        }
                        <span className={classes.cartCount}>{this.state.count}</span>
                        </div>

                    </div>
                </div>
                <div className={classes.Space}></div>
                {
                    this.state.showCart ? <Cart handleCartBack={this.handleCart} /> : null
                }
                {
                    this.state.showProfile ? <ProfileDrawer handleProfile={this.handleProfile} /> : null
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        location : state.red.location,
        user : state.usr,
        red : state.red
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeLocation : (location) => dispatch({
            type : 'CHANGE_LOCATION',
            location : location
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarAlt)