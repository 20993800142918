import React from 'react'
import Navbar from '../Navbar/Navbar'
import WebTop from '../WebTop/WebTop'
import USP from '../USP/USP'
import MobileSection from '../MobileSection/MobileSection'
import HomepageTop from '../HomepageTop/HomepageTop'
import Categories from '../Categories/Categories'
import Testimonial from '../TestimonialSection/Testimonial';
import Footer from '../Footer/Footer'
import firebase from '../../firebase';

class UserFAQs extends React.Component{
    state = {
        faqs: []
    }

    componentDidMount() {
        let comp = this
        firebase.database().ref().child("Masters").child("Faqs")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
                let data = []
                snapshot.forEach(function(snap) {
                    if(snap.val().PushId !== undefined) {
                        const user = {
                            Question: snap.val().Question,
                            Answer: snap.val().Answer
                        }
                        data.push(user)
                    }
                })
                comp.setState({
                    faqs: data
                })
            }
        })
    }

    render(){
        return(
            <React.Fragment>
                <Navbar />
                <div class="about-content" style={{marginTop:"20vh", padding: '10px 10%'}}>
                    <h2 style={{fontSize:'36px !important',textAlign:'center',marginBottom:'5%'}}>User Frequently Asked Questions</h2>
                    
                    {this.state.faqs.map((rowData, index) => (
                        <div key = {index}>
                            <h2>{rowData.Question}</h2>
                            <p>{rowData.Answer}</p>
                            <br />
                        </div>
                    ))}
                    <br/><br/>
                     </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default UserFAQs