import React from 'react';
import classes from './ProductGroceryCard.module.css';
const MealModal = (props) => {
  return (
    <div className={classes.Modal_Styles}>
      <div className={classes.ModalHeader}>
        <ion-icon name="close-outline" onClick={props.modalClose}></ion-icon>
      </div>
      <div className={classes.ModalContent}>
        <span className={classes.Heading}>Meal</span>
        <ul role="listbox" tabindex="0" aria-label="email list">
            <li tabindex="-1" role="option" aria-checked="false">
                <div class="custom-control custom-checkbox mb-0">
                    <input tabindex="-1" id="check1" class="custom-control-input" type="checkbox" /><label for="check1"></label>
                    Important information about your account
                </div>
            </li>
            <li tabindex="-1" role="option" aria-checked="false">
                <div class="custom-control custom-checkbox mb-0">
                    <input tabindex="-1" id="check1" class="custom-control-input" type="checkbox" /><label for="check1"></label>
                    Important information about your account
                </div>
            </li>

            <li tabindex="-1" role="option" aria-checked="false">
                <div class="custom-control custom-checkbox mb-0">
                    <input tabindex="-1" id="check1" class="custom-control-input" type="checkbox" /><label for="check1"></label>
                    Important information about your account
                </div>
            </li>
        </ul>
    </div>
    </div>
  );
};

export default MealModal;
