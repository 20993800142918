import React,{useState, useEffect , useCallback} from 'react'
import Footer from '../Footer/Footer'
import NavbarAlt from '../NavbarAlt/NavbarAlt'
import classes from './ShopDetails.module.css'
import ShopDetailsTop from '../ShopDetailsTop/ShopDetailsTop'
import ShopCategories from '../ShopCategories/ShopCategories'
import {connect} from 'react-redux'
import {database} from '../../firebase'

const ShopDetails = (props) => {

    const [promos, setPromos] = useState([]);

    const getPromo = useCallback(()=> {
        database.ref('/Masters/PromoCode/').once('value').then(snapshot =>{
            const allPromos = Object.values(snapshot.val());
            const activeProms = allPromos.filter((promos) => promos.Status === "Active");       
            console.log('activeProps',activeProms);    
            setPromos(activeProms)
        })
        .catch((error)=>{
        })
    },[])

    useEffect(()=>{
        getPromo(); 
     },[getPromo]);
    return(
        <React.Fragment>
            <NavbarAlt />
            <ShopDetailsTop />
            <ShopCategories />
            <div className={classes.Others}>
                <span className={classes.Heading}>Promos</span>
                <div className={classes.Main}>
                    {
                      promos.length ?  promos.map((prom,index) => (
                            <div className={classes.Item} key={index}>
                                <p>Get {prom.Discount} %</p>
                                <p>Use Code: {prom.PromoCode}</p>
                            </div>
                        ))
                        : <p>No Promo Available</p>
                    }
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
        shop : state.red.selectedShop
    }
}

export default connect(mapStateToProps)(ShopDetails)