import React from 'react'
import classes from './ModalMember.module.css'
import firebase from '../../firebase'


class ModalMemberDelivery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value:'',
            fullName: '',
            dob: '',
            emailId: '',
            gender: 'Select',
            alternateNumber: '',
            address: '',
            postalCode: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }
    
    
    handleChange(event){
        console.log(event.target.value)
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    componentDidMount() {
        window.scrollBy(0, 100);
    }

    

    handleSubmit(e) {
        e.preventDefault()

        let data = this.state

        if(data.fullName === '') {
            alert("Enter Full Name")
            document.getElementById("fullName").focus()
            return
        }

        if(data.dob === '') {
            alert("Select Date of Birth")
            document.getElementById("dob").focus()
            return
        }

        if(data.gender === 'Select') {
            alert("Select Gender")
            document.getElementById("gender").focus()
            return
        }

        if(data.emailId === '') {
            alert("Enter Email Id")
            document.getElementById("emailId").focus()
            return
        }

        if(data.alternateNumber === '') {
            alert("Enter Mobile Number")
            document.getElementById("alternateNumber").focus()
            return 
        }

        
        if(data.alternateNumber.length !== 10) {
            alert("Enter Correct Mobile Number")
            document.getElementById("alternateNumber").focus()
            return 
        }


        if(data.address === '') {
            alert("Enter Address")
            document.getElementById("address").focus()
            return
        }

        if(data.postalCode === '') {
            alert("Enter Postal Code")
            document.getElementById("postalCode").focus()
            return
        }

        var firebaseref = firebase.database().ref().child("EnquiryRider").push()
        firebaseref.child("PushId").set(firebaseref.getKey())
        firebaseref.child("FullName").set(data.fullName)
        firebaseref.child("Dob").set(data.dob)
        firebaseref.child("EmailId").set(data.emailId)
        firebaseref.child("Gender").set(data.gender)
        firebaseref.child("Number").set(data.alternateNumber)
        firebaseref.child("Address").set(data.address)
        firebaseref.child("PostaCode").set(data.postalCode)
        firebaseref.child("Status").set("Active")

        alert("Rider Enquiry Added Successfully!!")

        window.location.reload()
        
    }

    render() {
        return(
            <div className={classes.Container}>
                <div className={classes.Main} style={{height:'80%'}}>
                    <form>
                        <label style={{fontWeight:'bold',fontSize:'30px', float: "right", textAlign: "right", cursor: "pointer"}}><span style = {{cursor: "pointer"}} onClick = {this.props.triggerModel}>&times;</span></label>

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Full Name</label>
                        <input type = "text" id = "fullName" name = "fullName" onChange = {this.handleChange} placeholder="Eg . John Steve" />

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Date of Birth</label>
                        <input type="date" id = "dob" name = "dob" onChange = {this.handleChange} />
                        
                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Gender</label>    
                        <select style={{width:'100%'}} type = "select" id = "gender" name = "gender" onChange = {this.handleChange} placeholder="Gender">
                            <option value = "Select">Select</option>
                            <option value = "Male">Male</option>
                            <option value = "Female">Female</option>
                            <option value = "Others">Others</option>
                        </select>


                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Email ID</label>    
                        <input type="email" id = "emailId" name = "emailId" onChange = {this.handleChange} placeholder="Eg : abc@gmail.com" />

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Mobile Number</label>    
                        <input type = "number"
                            style={{width:'100%'}}
                            placeholder="Enter phone number"
                            value={this.state.alternateNumber}
                            name = "alternateNumber"
                            id = "alternateNumber"
                            onChange={this.handleChange} />
                            
                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Address</label>    
                        <input type = "text" id = "address" name = "address" onChange = {this.handleChange} placeholder="Eg : 88 Station Road Landon E54" />

                        <label style={{fontWeight:'bold',fontSize:'13px'}}>Postal Code</label>    
                        <input type = "text" id = "postalCode" name = "postalCode" onChange = {this.handleChange} placeholder="Lu1 11A" /> <br />
                        <button className={classes.AddressContinue}  onClick = {this.handleSubmit.bind(this)} style={{width:'86%'}}>Submit</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default ModalMemberDelivery