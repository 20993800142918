import React from 'react'
import classes from '../AvailableShopsCard/AvailableShopsCard.module.css'
import { NavLink } from 'react-router-dom'
import {connect} from 'react-redux'

const UnAvailableShopsCard = (props) => {

    let link = '/shopdetails'
    if(props.Category ==='Home Food' || props.Category === 'Food Delivery' ){
        link = '/categories'
    }

    console.log(props)
    return(
        // <NavLink to={props.Category==='Food Delivery' || props.Category==='Home Food' ? '/grocery ': "/shopdetails"} className={classes.Container} 
        // <NavLink to={link} className={classes.Container} 
            // onClick={() => {
            //     window.scrollTo(0, 0)
            //     localStorage.setItem('activeShop', JSON.stringify(props))
            //     if(props.Category ==='Home Food' || props.Category === 'Food Delivery' ){
            //         props.changeItemSubCategory(props);
            //         props.setActiveShop(props);

            //     }
            //     else{
            //         props.setActiveShop(props);
            //     }
                
                
                
            // }}
        // >
        <div className={classes.Container}>
            <div className={classes.Icon}>
                <img className={classes.Icon} src={props.icon} alt={props.Name} />
                {/* <img style={props.available ? null : {'filter' : 'grayscale(100%)'}} className={classes.Icon} src={props.icon} alt={props.name} /> */}
            </div>
            <div className={classes.Details}>
                <span className={classes.Name}>{props.StoreName}</span>
                <span className={classes.Delivery}>{props.DeliveryTime} min delivery</span>
                <span className={classes.Location}>{props.BusinessAddress}</span>
                <span className={classes.Discount}>{props.discount}</span>
            </div>
            </div>
        // </NavLink>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        setActiveShop : (shop) => dispatch({
            type : 'SET_ACTIVE_SHOP',
            payload : shop
        }),
        changeItemSubCategory : (category) => dispatch(
            {
                type : 'CHANGE_ITEM_CATEGORY',
                payload : category
            },)

    }
}

export default connect(null, mapDispatchToProps)(UnAvailableShopsCard)