import React from 'react'
import Footer from '../Footer/Footer'
import NavbarAlt from '../NavbarAlt/NavbarAlt'
import classes from './ProductDetails.module.css'
import Haldirams from '../../assets/haldirams.jpeg'
import {connect} from 'react-redux'
import {database} from '../../firebase'
import Modal from '../Portal/Modal'

class ProductDetails extends React.Component{

    state = {
        product : this.props.red.selectedShop.Products[this.props.red.productId],
        current : this.props.red.current,
        // currentNew : Object.keys(this.props.red.selectedShop.Products[this.props.red.productId].Weights),
        showAdd : true,
        quantity : 1,
        weightData: [],
        isOpen: false,
    }

    handleAdd = (productList) => {
        console.log('UserId', this.props.user.UserId)
        console.log('PushId', this.state.current.PushId)
        console.log('Current', this.state.current)
        console.log('Quantity : ', this.state.quantity, ' Price : ', Number(this.state.current.Price))

        if(this.props.user.UserId === null) {
            window.alert("Alert! Please Login to add item");
            this.setState({ isOpen: true });
            return
        }

        // console.log(this.)

        var vendorId = localStorage.getItem("vendorId")
        console.log("Vendor Id", vendorId)
        if(vendorId !== null && vendorId !== this.props.red.selectedShop.UserId ) {
            if(window.confirm("You can order only from one vendor. Are you want to remove the items from cart")) {
                database.ref().child("Users").child(this.props.user.UserId).child("Cart").remove()
                localStorage.removeItem("vendorId")

                // alert("Success!!. Cart Cleared. You can add the Item now")
                return
            } else {
                return
            }
        } else {
            localStorage.setItem("vendorId", this.props.red.selectedShop.UserId)
        }



            var cartRef = database.ref().child("Users").child(this.props.user.UserId).child("Cart").child(productList.PushId)
            cartRef.child("Image").set(this.state.product.ItemImage1)
            cartRef.child("Name").set(this.state.product.ItemName + " "+ productList.Name)
            cartRef.child("Price").set(productList.Price)
            cartRef.child("PushId").set(productList.PushId)
            cartRef.child("Qty").set("1")
            cartRef.child("Total").set(productList.Price)
            cartRef.child("Units").set(this.state.product.Unit)

        // this.setState({
        //     ...this.state,
        //     quantity : 1,
        //     showAdd : false
        // })
    }

    handleIncrease = (productId, weight) => {

        let comp = this

        database.ref().child("Users").child(comp.props.user.UserId).child("Cart").child(weight.PushId)
        .once("value", function(snapshot) {
            if(snapshot.exists()) {
                    console.log('Snapshot', snapshot.val())
                    let quantity = parseInt(snapshot.val().Qty) + 1 
                    let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)
                    let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(weight.PushId)
                    updateCartRef.child('Qty').set(String(quantity))
                    updateCartRef.child('Total').set(String(total))
                    .then(() => {
                        comp.setState({
                            quantity : quantity,
                            showAdd: false,
                        })
                    })
                }
        })

    }

    handleDecrease = (productId, weight) => {
        let comp = this
        // console.log('ShopID : ', shopId, ' ProductId  ', productId)
        database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(weight.PushId).once('value', snapshot => {
            console.log('Snapshot', snapshot.val())
            let quantity = parseInt(snapshot.val().Qty) - 1 
            let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)
            
                if(quantity !== 0) { 
                            let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(weight.PushId)
                            updateCartRef.child('Qty').set(quantity)
                            updateCartRef.child('RTotal').set(total)
                            updateCartRef.child('Total').set(total)
                    } else {
                        database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(weight.PushId).remove()
                    }

                            // let st = comp.state.structure
                            // st[index].items[i].quantity = quantity
                            // comp.setState({
                            //     structure : st,
                            //     cost : comp.state.cost + Number(st[index].items[i].Price)
                            // }, () => {
                            //     comp.props.changeTotal(comp.state.cost)
                            // } )
                })
            // })
        // })
    }

    componentDidMount() {
        console.log("Current New", this.state.product)
        let weightPushId = []
        let weightName = []
        let weightPrice = []

    Object.keys(this.state.product.Weights).map((iterator, index) => {           
        weightPushId.push(this.state.product.Weights[iterator].PushId)
        weightName.push(this.state.product.Weights[iterator].Name)
        weightPrice.push(this.state.product.Weights[iterator].Price)
    })


    this.setState({
        weightData: []
    })


    console.log("WeightPushId", weightPushId)
    database.ref('/Users').child(this.props.user.UserId).child('Cart').on('value', snapshot => {
        if(snapshot.val()){
            let cartPushId = []
            let cartQty = []
            let weightData = []
            snapshot.forEach(function (snapshot){
                cartPushId.push(snapshot.val().PushId)
                cartQty.push(snapshot.val().Qty)
            })

            console.log("CartPushId", cartPushId)

            for(let i = 0; i< weightPushId.length; i++) {
                var count = 0
                var k = 0
                for (let j = 0; j< cartPushId.length; j++) {
                    if(weightPushId[i] === cartPushId[j]) {
                        count = 1
                        k = j
                    }
                }
                if(count === 1) {
                console.log('Product exists in the cart')
                let weig = {
                    Name: weightName[i],
                    Price: weightPrice[i],
                    PushId: weightPushId[i],
                    ShowAdd: false,
                    Qty: cartQty[k]
                }
                weightData.push(weig)
            } else {
                let weig = {
                    Name: weightName[i],
                    Price: weightPrice[i],
                    PushId: weightPushId[i],
                    ShowAdd: true,
                    Qty: 1
                }
                weightData.push(weig)
            }
        }
                

            this.setState({
                weightData: weightData
            })
        } else {
            let weight = []
            for(let i = 0; i< weightPushId.length; i++) {
                let weig = {
                    Name: weightName[i],
                    Price: weightPrice[i],
                    PushId: weightPushId[i],
                    ShowAdd: true,
                    Qty: 1
                }
                weight.push(weig)
            }


            this.setState({
                weightData: weight
            })
        }
    });
}

    render(){
        console.log(this.props.red.selectedShop)
        console.log("Products", this.state.product)
        console.log("Weights", this.state.weightData)
        return(
            <div className={classes.Container}>
                <NavbarAlt />
                <Modal open = {this.state.isOpen} onClose = {()=> this.setState({ isOpen: false})}/>
                <div className={classes.Main}>
                    <div className={classes.Left}>
                        <img src={this.state.product.ItemImage1} alt="Product Image" />
                    </div>
                    <div className={classes.Right}>
                        <div className={classes.Brand}>
                            {/* <div className={classes.Veg} style={{border : `${this.props.currentProduct.veg ? 'solid 2px #7ed321' : 'solid 2px red'}`}}><div className={classes.VegCircle} style={{backgroundColor : `${this.props.currentProduct.veg ? '#7ed321' : 'red'}`}}></div></div> */}
                            <span>{this.state.product.Manufacture}</span>
                        </div>
                        <span className={classes.ProductName}></span>
                        <div className={classes.VarietyTable}>

                            {
                                this.state.weightData.map((iterator, index) => (
                                    <div className={classes.Item}>
                                        <span className={classes.ItemLeft}>{iterator.Name}</span>
                                        <span className={classes.ItemMiddle}>£ {iterator.Price} </span>
                                        {
                                            iterator.ShowAdd ? 
                                            <button className={classes.ButtonAdd} onClick={() => {this.handleAdd(iterator)}} >ADD</button> : 
                                            
                                            
                                            <div className={classes.Quantity}>
                                                <span className={classes.Highlite} onClick={() => this.handleDecrease(this.props.red.productId, iterator)}>-</span>
                                                <span className={classes.Quant}>{iterator.Qty ? iterator.Qty : 1}</span>
                                                <span className={classes.Highlite} onClick={() => this.handleIncrease(this.props.red.productId, iterator)}>+</span>
                                            </div> 
                                        }
                                    </div>
                                ))
                            }


                        </div>
                        <span className={classes.HRLine}></span>

                        <div className={classes.Details}>
                            <div className={classes.DetailsItem}>
                                <span className={classes.DetailsLeft}>Name </span>
                                <span className={classes.DetailsRight}>{this.state.product.ItemName}</span>
                            </div>
                            <div className={classes.DetailsItem}>
                                <span className={classes.DetailsLeft}>Type </span>
                                <span className={classes.DetailsRight}>{this.state.product.SubCategory}</span>
                            </div>
                            {/* <div className={classes.DetailsItem}>
                                <span className={classes.DetailsLeft}>Brand </span>
                                <span className={classes.DetailsRight}>{this.state.product.Manufacturer}</span>
                            </div> */}
                        {/* {
                            this.props.currentProduct.details.map((iterator, index) => (
                                <div className={classes.DetailsItem}>
                                    <span className={classes.DetailsLeft}>{Object.keys(iterator)[0]}</span>
                                    <span className={classes.DetailsRight}>{Object.values(iterator)[0]}</span>
                                </div>
                            ))
                        } */}
                        </div>
                        <span className={classes.HRLine}></span>
                        <div className={classes.Description}>
                            <span className={classes.DescriptionHeading}>Description</span>
                            <span className={classes.DescriptionContent}>{this.state.product.ItemDescription}</span>
                        </div>
                        <span className={classes.HRLine}></span>
                        <div className={classes.Features}>
                            <span className={classes.FeaturesHeading}>Key Features</span>
                            <span>{this.state.product.Features}</span>
                            {/* {
                                this.props.currentProduct.features.map((iterator, index) => (
                                    <div className={classes.FeaturesItem}>
                                        <div className={classes.FeaturesItemLeft}><ion-icon name="checkmark"></ion-icon></div>
                                        <div className={classes.FeaturesItemRight}>
                                            <span className={classes.FeaturesItemHeading}>{Object.keys(iterator)[0]}</span>
                                            <span className={classes.FeaturesItemContent}>{Object.values(iterator)[0]}</span>
                                        </div>
                                    </div>
                                ))
                            } */}

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        // currentProduct : state.red.currentProduct,
        user : state.usr,
        red : state.red
    }
}

export default connect(mapStateToProps)(ProductDetails)