import React from 'react'
import classes from './ProductGroceryCard.module.css'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import firebase, {database} from '../../../firebase';
import MealModal  from './MealModal';
import Modal from '../../Portal/Modal';
import HurrysIcon from '../../../assets/icon.png'

class ProductFoodCard extends React.Component {
    constructor(props, context){
        super(props, context);
        this.state={
            // current : this.props.productsList.Weights[Object.keys(this.props.productsList.Weights)[0]],
            showAdd : true,
            quantity : 1,
            foodType: 'NonVeg',
            showAddressModal: false,
            open: false,
            menuOpen: false,
            selectedFoodAddons: {},
            selectedFoodTypeMenu: {},
            cartTotal: 0,
            cartMenuTotal: 0,
            addonDetails: {},
            menuDetails: [],
            isOpen: false,
        }
    }
    

    handleModal = () => {
        this.setState({
            ...this.state,
            showAddressModal: !this.state.showAddressModal
        })
    }
    handleAdd = (productList) => {
        console.log('UserId', this.props.user.UserId)

        console.log('Product List', productList)


        if(this.props.user.UserId === null) {
            window.alert("Alert! Please Login to add item");
            this.setState({ isOpen: true });
            return
        }


        var vendorId = localStorage.getItem("vendorId")
        console.log("Vendor Id", vendorId)
        if(vendorId !== null && vendorId !== this.props.shop.UserId ) {
            if(window.confirm("You can order only from one vendor. Are you want to remove the items from cart")) {
                database.ref().child("Users").child(this.props.user.UserId).child("Cart").remove()
                localStorage.removeItem("vendorId")
                // alert("Success!!. Cart Cleared. You can add the Item now")
                return
            } else {
                return
            }
        } else {

            localStorage.setItem("vendorId", this.props.shop.UserId)
        }

        let cartPrice = ""
        if(productList.SellingPrice !== "") {
            cartPrice = productList.SellingPrice
        } else {
            cartPrice = productList.MarkingPrice
        }


        if(productList.AddOns === undefined && productList.Menu === undefined) {

            var cartRef = database.ref().child("Users").child(this.props.user.UserId).child("Cart").child(productList.PushId)
            cartRef.child("Customised").set("")
            cartRef.child("Details").set(productList.ItemDescription)
            cartRef.child("FoodType").set(productList.FoodType)
            cartRef.child("Image").set(productList.FoodImage)
            cartRef.child("Name").set(productList.ItemName)
            cartRef.child("OrderType").set("Portions")

            if(productList.SellingPrice !== "") {
                cartRef.child("Price").set(productList.SellingPrice)
                cartRef.child("RPrice").set(productList.SellingPrice)
                cartRef.child("RTotal").set(productList.SellingPrice)
                cartRef.child("Total").set(productList.SellingPrice)
            }
            else {
                cartRef.child("Price").set(productList.MarkingPrice)
                cartRef.child("RPrice").set(productList.MarkingPrice)
                cartRef.child("RTotal").set(productList.MarkingPrice)
                cartRef.child("Total").set(productList.MarkingPrice)
            }

            cartRef.child("PushId").set(productList.PushId)
            cartRef.child("Qty").set("1")
  

            this.setState({
                showAdd: false
            })

        } else if(productList.AddOns !== undefined && productList.Menu === undefined)  {
            this.setState({
                open: true,
                selectedFoodAddons: productList,
                cartTotal: cartPrice
            })
        } 
        else if(productList.AddOns === undefined && productList.Menu !== undefined)  {
            this.setState({
                menuOpen: true,
                selectedFoodTypeMenu: productList,
                cartMenuTotal: cartPrice
            })
        }
    }

    handleIncrease = (productList) => {

        let pushId = ""
        let comp = this

        let cartPrice = ""
        if(productList.SellingPrice !== "") {
            cartPrice = productList.SellingPrice
        } else {
            cartPrice = productList.MarkingPrice
        }

        console.log("aa", productList.AddOns)

    if(productList.AddOns !== undefined && productList.Menu === undefined)  {
        this.setState({
            open: true,
            selectedFoodAddons: productList,
            cartTotal: cartPrice
        })
    // } 
    // else if(productList.AddOns === undefined && productList.Menu !== undefined)  {
    //     this.setState({
    //         menuOpen: true,
    //         selectedFoodTypeMenu: productList,
    //         cartMenuTotal: cartPrice
    //     })
    
        } else {

            database.ref().child("Users").child(comp.props.user.UserId).child("Cart").child(productList.PushId)
            .once("value", function(snapshot) {
                if(snapshot.exists()) {
                        console.log('Snapshot', snapshot.val())
                        let quantity = parseInt(snapshot.val().Qty) + 1 
                        let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)
                        let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(productList.PushId)
                        updateCartRef.child('Qty').set(quantity)
                        updateCartRef.child('RTotal').set(total)
                        updateCartRef.child('Total').set(total)
                        .then(() => {
                            comp.setState({
                                // quantity : quantity,
                                open: false,
                                showAdd: false,
                            })
                        })
                    }
            })
        }
    }

    handleDecrease = (productList) => {
        let comp = this

        console.log(productList)

        if(productList.AddOns !== undefined) {
            alert("Go to Cart to clear Items")
            return
        }
        else {
            database.ref().child("Users").child(comp.props.user.UserId).child("Cart").child(productList.PushId)
            .once("value", function(snapshot) {
                if(snapshot.exists()) {
                        console.log('Snapshot', snapshot.val())
                        let quantity = parseInt(snapshot.val().Qty) - 1 
                        let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)
                        if(quantity !== 0) { 
                        let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(productList.PushId)
                        updateCartRef.child('Qty').set(quantity)
                        updateCartRef.child('RTotal').set(total)
                        updateCartRef.child('Total').set(total)

                        comp.setState({
                            // quantity : quantity,
                            open: false,
                            showAdd: false,
                        })

                    } else {
                        database.ref("Users").child(comp.props.user.UserId).child('Cart').child(productList.PushId).remove()
                        comp.setState({
                            showAdd: true
                        })
                    }
          
                    }
            })
        }
    }

    handleIconClick = () => {
        window.scroll(0,0)
        if(this.state.current){
            this.props.changeProduct(this.props.productId, this.state.current)
        }
    }

    componentDidMount() {
        console.log('Card props : ', this.props);
        console.log(this.props.productsList)
        console.log(this.props.productLength)
        // if(!this.props.productsList.Weights){
        //     const productId =this.props.productId;
        //     // const foodType = this.props..Products[productId].FoodType;
        //     this.setState({
        //         ...this.state,
        //         // foodType
        //     })
        // }

        let comp = this


        console.log(comp.props.productId)

        if(this.props.user.UserId !== null) {

            database.ref('/Users').child(comp.props.user.UserId).child('Cart').on('value', snapshot => {
                if(snapshot.exists()){
                    let quantity = 0
                    snapshot.forEach(function(snapshot) {
                        if(comp.props.productList !== undefined) {
                            if(snapshot.val().PushId.includes(comp.props.productsList.PushId)) {
                                quantity = parseInt(quantity) + parseInt(snapshot.val().Qty) 
                            }
                        }
                    })
                    if(quantity > 0) {
                        console.log('Product exists in the cart')
                        comp.setState({
                            ...comp.state,
                            showAdd : false,
                            quantity : quantity
                        })
                    }

                    else {
                        console.log('Product does not exist')
                        comp.setState({
                            ...comp.state,
                            showAdd : true,
                            quantity : 0
                        })
                    }

                } else {
                comp.setState({
                    ...comp.state,
                    showAdd : true,
                    quantity : 0
                })
            }
        });
    }


    }

    closeModal() {
        this.setState({
            open: false
        })
    }

    closeMenuModal() {
        this.setState({
            menuOpen: false,
            menuDetails: []
        })
    }

    addOnsOnChange = (e, iterator) => {
        let addonDetails = {
            Name: this.state.selectedFoodAddons.AddOns[iterator].Name,
            Price: this.state.selectedFoodAddons.AddOns[iterator].Price,
            PushId: iterator,
        }

        let total = (parseFloat(this.state.cartTotal) + parseFloat(this.state.selectedFoodAddons.AddOns[iterator].Price)).toFixed(2)

        this.setState({
            addonDetails: addonDetails,
            cartTotal: total
        })
    }

    addItemsWithAddons = () => {
        let productList = this.state.selectedFoodAddons
        let addonsList = this.state.addonDetails


        console.log("User Id inside Addons", this.props.user.UserId)


        let name = ""
        let price = ""
        let addonsPushid = ""
        let nameForPushId = ""
        let cartRef
        let comp = this
        let cartPushid = ""

        let cartPrice = ""
        if(productList.SellingPrice !== "") {
            cartPrice = productList.SellingPrice
        } else {
            cartPrice = productList.MarkingPrice
        }

        if(addonsList.Name !== undefined) {
            name = "Addons - " + addonsList.Name
            nameForPushId = addonsList.Name
        } else {
            name = ""
            nameForPushId = ""
        }

        if(addonsList.Price !== undefined) {
            price = this.state.cartTotal
        } else {
            price = cartPrice
        }

        if(addonsList.PushId !== undefined) {
            addonsPushid = addonsList.PushId
            cartPushid = productList.PushId + nameForPushId
        } else {
            addonsPushid = "Addons"
            cartPushid = productList.PushId
        }

        console.log(addonsList)

        database.ref().child("Users").child(comp.props.user.UserId).child("Cart").child(cartPushid)
        .once("value", function(snapshot) {
            if(snapshot.exists()) {
                    console.log('Snapshot', snapshot.val())
                    let quantity = parseInt(snapshot.val().Qty) + 1 
                    let total = (parseFloat(snapshot.val().Price) * parseFloat(quantity)).toFixed(2)
                    let updateCartRef = database.ref('/Users').child(comp.props.user.UserId).child('Cart').child(cartPushid)
                    updateCartRef.child('Qty').set(String(quantity))
                    updateCartRef.child('RTotal').set(String(total))
                    updateCartRef.child('Total').set(String(total))
                    .then(() => {
                        comp.setState({
                            // quantity : quantity,
                            open: false,
                            showAdd: false,
                            addonDetails: {}
                        })
                    })
            } else {


            cartRef = database.ref().child("Users").child(comp.props.user.UserId).child("Cart").child(cartPushid)
            cartRef.child("PushId").set(cartPushid)
            cartRef.child("Customised").set(name)
            cartRef.child("Details").set(productList.ItemDescription)
            cartRef.child("FoodType").set(productList.FoodType)
            cartRef.child("Image").set(productList.FoodImage)
            cartRef.child("Name").set(productList.ItemName + " "+ name)
            cartRef.child("Price").set(price)
            cartRef.child("Qty").set("1")
            cartRef.child("RPrice").set(comp.state.cartTotal)
            cartRef.child("RTotal").set(comp.state.cartTotal)
            cartRef.child("Total").set(comp.state.cartTotal)

        comp.setState({
            open: false,
            showAdd: false,
            addonDetails: {}
        })
    }
    })

    }

    menuOnChange = (e, itemIterator, menuIterator, req) => {

        let totalItemsDetails = this.state.menuDetails

        console.log("Menu Detials", totalItemsDetails)

        for(let i = 0; i< totalItemsDetails.length; i++) {
            if(itemIterator === totalItemsDetails[i].PushId) {
                document.getElementById(`addOnName${this.state.selectedFoodTypeMenu.Items[itemIterator].PushId}`).checked = false
                
                totalItemsDetails.splice(i, 1);

                let total = (parseFloat(this.state.cartMenuTotal) - parseFloat(this.state.selectedFoodTypeMenu.Items[itemIterator].Price)).toFixed(2)

                this.setState({
                    menuDetails: totalItemsDetails,
                    cartMenuTotal: total
                })
                return

            }
        }


        let count = 0

        if(req !== "0") {

            for(let i = 0; i< totalItemsDetails.length; i++) {
                if(menuIterator === totalItemsDetails[i].Menu) {
                    count = count + 1
                }
            }

            console.log("Count", count)

            if(count === parseInt(req)) {
                document.getElementById(`addOnName${this.state.selectedFoodTypeMenu.Items[itemIterator].PushId}`).checked = false

                alert("Can't able to add items. Limit Exceeded")
                return
            }

        }

            let menuDetails = {
                Name: this.state.selectedFoodTypeMenu.Items[itemIterator].Name,
                Price: this.state.selectedFoodTypeMenu.Items[itemIterator].Price,
                Menu: this.state.selectedFoodTypeMenu.Items[itemIterator].Menu,
                PushId: itemIterator,
            }

            console.log(menuDetails)

            totalItemsDetails.push(menuDetails)



            let total = parseFloat(this.state.cartMenuTotal) + parseFloat(this.state.selectedFoodTypeMenu.Items[itemIterator].Price)

            this.setState({
                menuDetails: totalItemsDetails,
                cartMenuTotal: total
            })
    }

     addItemsWithMenu = () => {
        let productList = this.state.selectedFoodTypeMenu
        let menuList = this.state.menuDetails
        let comp = this


        let menuName = ""
        let menuPrice = 0

        for(let i=0; i< menuList.length; i++) {
            menuName = menuName + " " + menuList[i].Name
            menuPrice = (parseFloat(menuPrice) + parseFloat(menuList[i].Price)).toFixed(2)
        }

        console.log("Menu Name", menuName)
        console.log("Menu Price", menuPrice)


                var cartRef = database.ref().child("Users").child(comp.props.user.UserId).child("Cart").push()
                cartRef.child("PushId").set(cartRef.getKey())
                cartRef.child("Customised").set(productList.ItemName + " " + menuName)
                cartRef.child("Details").set(productList.ItemDescription)
                cartRef.child("FoodType").set(productList.FoodType)
                cartRef.child("Image").set(productList.FoodImage)
                cartRef.child("Name").set(productList.ItemName + " " + menuName)
                cartRef.child("Price").set(comp.state.cartMenuTotal)
                cartRef.child("Qty").set("1")
                cartRef.child("RPrice").set(comp.state.cartMenuTotal)
                cartRef.child("RTotal").set(comp.state.cartMenuTotal)
                cartRef.child("Total").set(comp.state.cartMenuTotal)


            comp.setState({
                menuOpen: false,
                showAdd: true,
                menuDetails: []
            })
    //     }s
    // })
}

    render(){
        return(
              
            <div  className={classes.Container}>
            {
                this.props.few ?
                    <span className={classes.Few}>ONLY FEW LEFT</span> : 
                    null
            }
                { this.state.showAddressModal ? 
                    <MealModal modalClose={this.handleModal} />
                    : null
                }
                <Modal open = {this.state.isOpen} onClose = {()=> this.setState({ isOpen: false})}/>
                {console.log(this.props.user.UserId)}
                {this.props.productsList.FoodImage == "No" || this.props.productsList.FoodImage == "" ?
                <img src = {HurrysIcon} height = "200" width = "200" alt = "" /> :
                <img src={this.props.productsList.FoodImage} height = "200" width = "200"  alt="Product Icon" /> }
                <div className={classes.Details}>
                    <div className={classes.Brand}>
                        {
                            // !this.props.productsList.Weights ? <div className={this.state.foodType === "Veg"?  classes.VegBorder : this.state.foodType === 'NonVeg' ? classes.NonVegBorder : ''}>
                            //     <div className={this.state.foodType === "Veg"?  classes.NonVegDot : this.state.foodType === 'NonVeg' ? classes.NonVegDot : '' }>
                            //         </div></div> : null
                        }
                        
                    </div>
                    <span className={classes.Name}>{this.props.productsList.ItemName}</span>
                    <span className={classes.Name}>{this.props.productsList.ItemDescription}</span><br />
                    <div className={classes.HRLine}></div>
                    <div className={classes.Prices}>
                        <div className={classes.Left}>
                            {this.props.productsList.SellingPrice !== "" ?
                            <span className={classes.TruePrice}>£ {this.props.productsList.SellingPrice}</span> :
                            <span className={classes.TruePrice}>£ {this.props.productsList.MarkingPrice}</span> }
                            {/* <span className={classes.TruePrice}>£ {this.state.showAdd ? this.state.current.Price  : `${this.state.quantity*Number(this.state.current.Price) ? this.state.quantity*Number(this.state.current.Price) : this.state.current.Price}`}</span> */}
                            {/* <span className={classes.StdPrice}>₹{this.state.current.stdPrice}</span> */}
                        </div>
                        {
                            this.state.showAdd ? 
                            <button className={classes.ButtonAdd} onClick={() => this.handleAdd(this.props.productsList)} >ADD</button>
                            : 
                            <div className={classes.Quantity}>
                                <span className={classes.Highlite} onClick={() => this.handleDecrease(this.props.productsList)}>-</span>
                                <span className={classes.Quant}>{this.state.quantity ? this.state.quantity : 1}</span>
                                <span className={classes.Highlite} onClick={() => this.handleIncrease(this.props.productsList)}>+</span>
                            </div>
                        }
                        
                    </div>
                </div>

                {this.state.open &&
                <div id="myModal" className="modal">

                    <div className={classes.ModalContent}>
                        <span className="close" style={{position: 'absolute',top: 20,right: 20 }} onClick = {this.closeModal.bind(this)}>&times;</span>
                        <div className={classes.Details} style={{left: -40,position:'relative'}}>
                            <div className={classes.Brand} >
                                {
                                    !this.props.productsList.Weights ? <div className={this.state.selectedFoodAddons.FoodType === "Veg"?  classes.VegBorder : this.state.selectedFoodAddons.FoodType === 'NonVeg' ? classes.NonVegBorder : ''}>
                                        <div className={this.state.selectedFoodAddons.FoodType === "Veg"?  classes.NonVegDot : this.state.selectedFoodAddons.FoodType === 'NonVeg' ? classes.NonVegDot : '' }>
                                            </div></div> : null
                                }
                            <h3 style={{marginLeft: 10}}>{this.state.selectedFoodAddons.ItemName}</h3>
                            </div>
                        </div>
                        {this.props.productsList.SellingPrice !== "" ?
                        <h4 style={{color: '#888'}}>{"£"+ this.state.selectedFoodAddons.SellingPrice}</h4>: 
                        <h4 style={{color: '#888'}}>{"£"+ this.state.selectedFoodAddons.MarkingPrice}</h4> }
                        <br/>
                        <hr />
                        <br/>
                        <h2><b>Addons</b></h2>
                        <br/>      
                        {Object.keys(this.state.selectedFoodAddons.AddOns).map((iterator, index) => ( 
                            <div>
                                <input type="radio" id="addOnName" name="addOnName" value="addOnName" onChange={(event) => this.addOnsOnChange(event, iterator)}/>&nbsp;
                                <label for="addOnName">{this.state.selectedFoodAddons.AddOns[iterator].Name} &nbsp; &nbsp; <span style={{color: '#888'}}>{"£"+ this.state.selectedFoodAddons.AddOns[iterator].Price}</span></label> 
                            </div>
                        ))}
                        <div className={classes.CartTotal}>
                            <p>Total: {"£"+ this.state.cartTotal}</p>
                            <p style={{ cursor: 'pointer'}} onClick={() => this.addItemsWithAddons()} >ADD ITEM</p>
                        </div>
                    </div>
                </div>}

                    {this.state.menuOpen ?
                <div id="myModal" className="modal">

                    <div className={classes.ModalContent}>
                        <span className="close" style={{position: 'absolute',top: 20,right: 20 }} onClick = {this.closeMenuModal.bind(this)}>&times;</span>
                        <div className={classes.Details} style={{left: -40,position:'relative'}}>
                            <div className={classes.Brand} >
                                {
                                    !this.props.productsList.Weights ? <div className={this.state.selectedFoodTypeMenu.FoodType === "Veg"?  classes.VegBorder : this.state.selectedFoodTypeMenu.FoodType === 'NonVeg' ? classes.NonVegBorder : ''}>
                                        <div className={this.state.selectedFoodTypeMenu.FoodType === "Veg"?  classes.NonVegDot : this.state.selectedFoodTypeMenu.FoodType === 'NonVeg' ? classes.NonVegDot : '' }>
                                            </div></div> : null
                                }
                            <h3 style={{marginLeft: 10}}>{this.state.selectedFoodTypeMenu.ItemName}</h3>
                            </div>
                        </div>
                        {this.state.selectedFoodTypeMenu.SellingPrice !== "" ?
                        <h4 style={{color: '#888'}}>{"£"+ this.state.selectedFoodTypeMenu.SellingPrice}</h4>: 
                        <h4 style={{color: '#888'}}>{"£"+ this.state.selectedFoodTypeMenu.MarkingPrice}</h4> }
                        <br/>
                        <hr />
                        <br/>
                        <br/>      
                        {Object.keys(this.state.selectedFoodTypeMenu.Menu).map((iterator, index) => ( 
                        <div>
                        <p>{this.state.selectedFoodTypeMenu.Menu[iterator].Name} &nbsp; &nbsp; {"("+ this.state.selectedFoodTypeMenu.Menu[iterator].Required + " Required)"}</p> <br />
                            {Object.keys(this.state.selectedFoodTypeMenu.Items).map((itemIterator, index) => ( 
                                <div>
                                 {this.state.selectedFoodTypeMenu.Items[itemIterator].Menu=== iterator ? <>
                                    <input type="checkbox" id={`addOnName${this.state.selectedFoodTypeMenu.Items[itemIterator].PushId}`} name="addOnName" value="addOnName" onChange={(event) => this.menuOnChange(event, itemIterator, iterator, this.state.selectedFoodTypeMenu.Menu[iterator].Required)}/>&nbsp;
                                    <label for="addOnName">{this.state.selectedFoodTypeMenu.Items[itemIterator].Name} &nbsp; &nbsp; {"£"+ this.state.selectedFoodTypeMenu.Items[itemIterator].Price}  </label> <br /> </> : null }
                                </div>
                            ))} <br />
                           <br />
                        </div> ))}
                        <div className={classes.CartTotal}>
                            <p>Total: {"£"+ this.state.cartMenuTotal}</p>
                            <p style={{ cursor: 'pointer'}} onClick={() => this.addItemsWithMenu()} >ADD ITEM</p>
                        </div>
                    </div>

                    {/* <div className="modal-content">
                    <span className="close" onClick = {this.closeMenuModal.bind(this)}>&times;</span>
                    <p><b>{this.state.selectedFoodTypeMenu.ItemName}</b></p>
                    {this.state.selectedFoodTypeMenu.SellingPrice !== "" ?
                    <p>{"£"+ this.state.selectedFoodTypeMenu.SellingPrice}</p> :
                    <p>{"£"+ this.state.selectedFoodTypeMenu.MarkingPrice}</p> }

                    <br />
                    <hr />
                    <br />
                    {Object.keys(this.state.selectedFoodTypeMenu.Menu).map((iterator, index) => ( 
                        <div>
                        <p>{this.state.selectedFoodTypeMenu.Menu[iterator].Name} &nbsp; &nbsp; {"("+ this.state.selectedFoodTypeMenu.Menu[iterator].Required + " Required)"}</p> <br />
                            {Object.keys(this.state.selectedFoodTypeMenu.Items).map((itemIterator, index) => ( 
                                <div>
                                 {this.state.selectedFoodTypeMenu.Items[itemIterator].Menu=== iterator ? <>
                                    <input type="checkbox" id="addOnName" name="addOnName" value="addOnName" onChange={(event) => this.menuOnChange(event, itemIterator, this.state.selectedFoodTypeMenu.Menu[iterator].Required)}/>&nbsp;
                                    <label for="addOnName">{this.state.selectedFoodTypeMenu.Items[itemIterator].Name} &nbsp; &nbsp; {"£"+ this.state.selectedFoodTypeMenu.Items[itemIterator].Price}  </label> <br /> </> : null }
                                </div>
                            ))} <br />
                           <br />
                        </div> ))}
                    <br />
                    <p>Total: {"£"+ this.state.cartMenuTotal}</p> <br />
                        <button className={classes.ButtonAdd} onClick={() => this.addItemsWithMenu()} >ADD ITEM</button>
                    </div> */}

                    </div> : null }
            </div>
           
        )
    }
}

{/* <span>{iterator.stdprice}</span> */}

const mapStateToProps = state => {
    return{
        red : state.red,
        user : state.usr
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChangeTotal : (price) => dispatch({
            type : 'CHANGE_TOTAL',
            payload : price
        }),
        changeProduct : (id, current) => dispatch({
            type : 'CHANGE_PRODUCT_ID',
            payload : id,
            current : current
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFoodCard)