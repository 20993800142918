import React, {useState} from 'react';
import styles from './rating.module.css';
const Star = ({ marked, starId }) => {
    return (
      <span data-star-id={starId} className={styles.star} role="button">
        {marked ? '\u2605' : '\u2606'}
      </span>
    );
  };

  const Rating = ({ value }) => {
    const [rating, setRating] = useState(parseInt(value) || 0);
    return (
      <div>
        {Array.from({ length: 5 }, (v, i) => (
          <Star
            starId={i + 1}
            key={`star_${i + 1}`}
            marked={rating >= i + 1}
          />
        ))}
      </div>
    );
  };
  export default Rating;