import React, {useState, useEffect } from 'react';
import * as LoIcons from 'react-icons/io';
import styles from './CouponContent.module.css';
import { database } from '../../../firebase';


const  CouponContent = ({onClose}) => {
    const [promos, setPromos] = useState([]);

    useEffect(()=>{
        fetchPromoData()
    },[])

    const fetchPromoData = ()=>{
        database.ref("/Masters/PromoCode/").once('value').then(snapshot =>{
            const allPromo = Object.values(snapshot.val());
            setPromos(allPromo);

        })
    }
    return (
        <>
        <div className = {styles.coupon_wrapper}>
             <div className = {styles.coupon_header}>
                <p style = {{fontWeight:600}}>Apply Coupon</p>
                <LoIcons.IoIosClose onClick = {onClose} style = {{fontWeight:900,fontSize:50,}} />
            </div>


            {/* <div className = {styles.inputArea}>
                <div className = {styles.input_container}>
                    <input type = "text" placeholder = "Enter Coupon Code" />
                    <p>Apply Coupon</p>
                </div>
            </div> */}
            <div className = {styles.divider}></div>
            <div className = {styles.container}>

                {
                    promos.map((value, index) =>(
                        <div className = {styles.box}>
                            <div className = {styles.content}>
                                <div className = {styles.up}>
                                    <div className = {styles.coupon_code}>
                                        <p>{value.PromoCode}</p>
                                    </div>                                
                                </div>
                                <div className = {styles.down}>
                                    <p>Get {value.Discount}% Off</p>
                                    <div className = {styles.down_content}>
                                        {
                                            value.Category === "Discount" ? (
                                              <p>Get {value.Discount }% off on your next order above {value.MinAmout}. Maximum discount is {value.MaxAmount}.</p>
                                            ): (
                                                value.Category === "Flat" ? (
                                                    <p>Get  flat {value.Discount }% off on your next order above {value.MinAmout}. </p>
                                                ): null
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                
            </div>
            
        </div>
        </>
    )
}

export default CouponContent;

