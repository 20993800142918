import React from 'react';
import styles from './Footer.module.css';
import hurraylogo from '../../assets/hurraylogo.png';
import {NavLink} from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';

function Footer() {
    return (
        <div className={styles.footer}>
      <div className = {styles.footer_up}>
          <div className = {styles.box}>
            <img src = {hurraylogo}/>
            <div className = {styles.icon_wrapper}>
              <FaIcons.FaFacebookF onClick={()=> window.open("https://m.facebook.com/pg/hurrysuk/posts/", "_blank")} style = {{color: 'white',cursor:"pointer"}}/>
              <FaIcons.FaInstagram  onClick={()=> window.open("https://www.instagram.com/hurrysuk/?utm_medium=copy_link", "_blank")} style = {{color: 'white', marginLeft:'30px',cursor:"pointer"}}/>
            </div>
            
          </div>
          <div className = {styles.box}>
            <p className = {styles.label} >COMPANY</p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/about">About Us</NavLink></p>
            <p>Careers</p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/contact">Contact Us</NavLink></p>
          </div>
          <div className = {styles.box}>
            <p className = {styles.label} >CONTACT</p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/help-and-support">Help and Support</NavLink></p>
        
            <p><NavLink onClick={() => window.scroll(0,0)} to="/delivery">Become a Rider</NavLink></p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/vendor">Become a Seller</NavLink></p>
          </div>
          <div className = {styles.box}>
            <p className = {styles.dummy} >CONTACT</p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/terms">Terms & Conditions</NavLink></p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/privacy">Privacy Policy</NavLink></p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/cancellation">Refund & Cancellation</NavLink></p>
          </div>

          <div className = {styles.box}>
            <p className = {styles.label} >FAQs</p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/userFAQs">User</NavLink></p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/vendorFAQs">Vendor</NavLink></p>
            <p><NavLink onClick={() => window.scroll(0,0)} to="/deliveryFAQs">Delivery</NavLink></p>
          </div>
      </div>
      <div className = {styles.footer_down}>
        <p>© Hurrys Corporation </p>
      </div>
    </div>
    )
}

export default Footer;
